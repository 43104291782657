import { render } from "@testing-library/react";
import axios from "axios";
import React from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import "./PDFFile.css";

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="print-component">
      {props.children}
    </div>
  );
});

const PDFFile = () => {
  const [data, setData] = useState(null);
  const componentRef = useRef();
  let { id, type, year, month, startDate } = useParams();

  async function getData() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/${id}/outlet-transaction`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: type,
          date: startDate,
          month: month,
          year: year,
        },
      })
      .then((res) => {
        setData(res.data.shifts);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container">
      <ReactToPrint
        trigger={() => (
          <button className="btn btn-blue">Print this out!</button>
        )}
        content={() => componentRef.current}
      />
      <ComponentToPrint ref={componentRef}>
        <div className="header">
          <h1 className="text-center">Stream Ok</h1>
          <h2 className="text-center">REKAPAN PENDAPATAN</h2>
          <h3 className="text-center">
            {data?.start_date} - {data?.end_date}
          </h3>
        </div>

        <table className="recap">
          <tbody>
            <tr>
              <td className="text-left">Total Pemasukkan</td>
              <td className="text-right">
                {data?.total_earnings_recap
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
            <tr>
              <td className="text-left">Total Pengeluaran</td>
              <td className="text-right">
                (
                {data?.total_spending_recap
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                )
              </td>
            </tr>
            <tr className="border-top">
              <td className="text-left">
                <b>TOTAL</b>
              </td>
              <td className="text-right">
                <b>
                  {data?.net_earnings_recap
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </b>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="body">
          <h2 className="text-center">DETAIL PENDAPATAN</h2>
          <h3 className="text-center">
            {data?.start_date} - {data?.end_date}
          </h3>

          {data?.data
            .filter(
              (item) =>
                item.transactions.data.total_earnings !== "0" ||
                item.transactions.data.total_spending !== "0"
            )
            .map((detail, index) => {
              return (
                <table key={index} className="recap-detail">
                  <thead>
                    <tr>
                      <th scope="col" colSpan="2" className="text-center">
                        SHIFT {index + 1} ({detail.staff_name})
                      </th>
                      <th scope="col" className="text-center">
                        {detail.time_start}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="recap-detail-header">
                      <td>&nbsp;</td>
                      <td>
                        <b>PEMASUKKAN</b>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    {detail?.transactions.data.earnings.map((trx, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{trx.title}</td>
                          <td className="text-right">
                            {trx.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="recap-detail-total-earning">
                      <td>&nbsp;</td>
                      <td>
                        <b>Total Pemasukkan</b>
                      </td>
                      <td className="text-right">
                        {detail?.transactions.data.total_earnings
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr className="recap-detail-header">
                      <td>&nbsp;</td>
                      <td>
                        <b>PENGELUARAN</b>
                      </td>
                      <td>&nbsp;</td>
                    </tr>

                    {detail?.transactions.data.spending.map((trx, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{trx.title}</td>
                          <td className="text-right">
                            (
                            {trx.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            )
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="recap-detail-total-spending">
                      <td>&nbsp;</td>
                      <td>
                        <b>Total Pengeluaran</b>
                      </td>
                      <td className="text-right">
                        (
                        {detail?.transactions.data.total_spending
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        )
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="recap-detail-total">
                      <td>&nbsp;</td>
                      <td className="text-start">
                        <b>TOTAL</b>
                      </td>
                      <td className="text-right">
                        {detail?.transactions.data.net_earnings
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
        </div>
      </ComponentToPrint>
    </div>
  );
};

export default PDFFile;
