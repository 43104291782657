import React from "react";
import styled from "styled-components";
import PieChart from "./PieChart";
import DoughnutChart from "./DoughnutChart";
import { useSelector } from "react-redux";

const RevenueDetail = () => {
  const { analyticRevenueDetail, analyticRevenueMethod } = useSelector(
    (state) => state.analytic
  );
  return (
    <RevenueDetailWrapper className="revenue-list">
      <div className="revenue-item">
        <div className="title">Kategori</div>
        <div className="category">
          <div className="revenue">
            <PieChart />
            <div className="revenue-detail">
              <ul>
                <li className="billing-ellipse">
                  <span>Billing</span>
                  <div className="amount-revenue">
                    Rp{" "}
                    {analyticRevenueDetail?.billing
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </div>
                </li>
                <li className="food-ellipse">
                  <span>Food</span>
                  <div className="amount-revenue">
                    Rp{" "}
                    {analyticRevenueDetail?.food
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </div>
                </li>
                <li className="refund-ellipse">
                  <span>Refund</span>
                  <div className="amount-revenue">
                    Rp{" "}
                    {analyticRevenueDetail?.refund
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      .replace("-", "")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">&nbsp;</div>
      <div className="revenue-item">
        <div className="title">Metode Pembayaran</div>
        <DoughnutChart />
        <div className="method-payment">
          <div className="cash">
            <ul>
              <li className="cash-ellipse">
                <span>Cash</span>
                <div className="amount-revenue">
                  Rp{" "}
                  {analyticRevenueMethod?.cash
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </div>
              </li>
            </ul>
          </div>
          <div className="qris">
            <ul>
              <li className="qris-ellipse">
                <span>QRIS</span>
                <div className="amount-revenue">
                  Rp{" "}
                  {analyticRevenueMethod?.qris
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="divider">&nbsp;</div>
      <div className="revenue-item">
        <div className="title">Perhitungan Total</div>
        <table>
          <tbody>
            <tr>
              <td className="table-title">Total pemasukan</td>
              <td className="table-content">
                Rp{" "}
                {(
                  parseInt(analyticRevenueDetail?.billing) +
                  parseInt(analyticRevenueDetail?.food)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
            <tr>
              <td className="table-title">Total refund</td>
              <td className="table-content">
                Rp{" "}
                {analyticRevenueDetail?.refund
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  .replace("-", "")}
              </td>
            </tr>
            <tr>
              <td className="table-title">Total pendapatan</td>
              <td className="table-content">
                Rp{" "}
                {(
                  parseInt(analyticRevenueDetail?.billing) +
                  parseInt(analyticRevenueDetail?.food) +
                  parseInt(analyticRevenueDetail?.refund)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </RevenueDetailWrapper>
  );
};

const RevenueDetailWrapper = styled.div`
  display: flex;
  margin-top: 48px;
  div.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #b0bec4;
  }
  div.category {
    display: flex;
    div.revenue {
      display: flex;
      div.revenue-detail {
        margin-left: 56px;
        margin-top: 26px;
        div.amount-revenue {
          font-family: "Montserrat", sans-serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #b0bec4;
          margin-top: 8px;
        }
        li {
          font-family: "Nunito Sans", sans-serif;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #b0bec4;
          margin-bottom: 32px;
        }
        li::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 8px;
          padding-top: 10px;
        }
        li.billing-ellipse::before {
          background: #29d1f5;
        }
        li.food-ellipse::before {
          background: #f06500;
        }
        li.refund-ellipse::before {
          background: #ed213a;
        }
      }
    }
  }
  div.sc-ieecCq.YEogA {
    width: 229px;
  }
  div.method-payment {
    display: flex;
    margin-top: 20px;
    div.amount-revenue {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #b0bec4;
      margin-top: 8px;
    }
    li {
      font-family: "Nunito Sans", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #b0bec4;
      margin-bottom: 32px;
    }
    li::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 8px;
      padding-top: 10px;
    }
    li.cash-ellipse::before {
      background: #24ffca;
    }
    li.cash-ellipse {
      margin-right: 32px;
    }
    li.qris-ellipse::before {
      background: #9870fc;
    }
  }
  table {
    margin-top: 40px;
  }
  td.table-title {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #b0bec4;
    padding-right: 63px;
    padding-bottom: 34px;
  }
  td.table-content {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #b0bec4;
    padding-left: 29px;
    padding-bottom: 34px;
  }
  tr:nth-child(2) td.table-content {
    border-bottom: 1.5px solid #303436;
  }
  tr:nth-child(3) td.table-title {
    padding-top: 37px;
  }
  tr:nth-child(3) td.table-content {
    padding-top: 37px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 8px rgba(0, 158, 255, 0.4);
  }
  div.divider {
    width: 0px;
    height: 257px;
    border: 1.5px solid #303436;
    background: #303436;
    margin: 0 45px;
  }
`;

export default RevenueDetail;
