import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DataSent from "../assets/images/data-sent.svg";
import { addCookies, checkCookies } from "../utils/cookies";

const ContactUsSuccess = ({ isSuccess, openModal }) => {
  return (
    <Wrapper className={checkCookies("SBS_CUS") || isSuccess ? "" : "hidden"}>
      <img src={DataSent} alt="" className="mx-auto mt-40" />
      <h1>Kamu sudah mengirim data</h1>
      <p>
        Terima Kasih telah menghubungi kami. Kamu bisa menghubungi kami dalam 1
        x 24 jam.
      </p>
      <div className="open-modal" onClick={openModal}>
        Lihat data yang kamu kirim
      </div>
      <Link to="/">
        <button>Kembali ke Beranda</button>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 30px;
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
    margin-top: 16px;
  }
  p {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-top: 24px;
  }
  div.open-modal {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #009eff;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
  }
  button {
    background: #009eff;
    box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.5);
    border-radius: 8px;
    padding: 20px 40px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 900;
    font-size: 16px;
    color: white;
    margin-top: 34px;
  }

  @media only screen and (max-width: 600px) {
    img {
      margin-top: 40px;
    }
    h1 {
      font-size: 32px;
      margin: 16px 59px;
    }
    p {
      margin: 16px 30px;
    }
    div.open-modal {
      font-size: 12px;
    }
    button {
      padding: 12px 32px;
      font-size: 14px;
      margin-top: 48px;
    }
  }
`;

export default ContactUsSuccess;
