import React from "react";
import styled from "styled-components";
import iCafe from "../assets/images/iCafe.svg";
import iCafeFull from "../assets/images/iCafe-full.svg";
import icon1 from "../assets/images/plus-dark.svg";
import icon2 from "../assets/images/plus-light.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Wrapper data-aos="zoom-in" data-aos-duration="200" data-aos-once="true">
      <div className="main">
        <h1
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="200"
          data-aos-once="true"
        >
          Lihat betapa mudahnya kamu bisa mengelola warnet!
        </h1>
        <p
          data-aos="fade-right"
          data-aos-delay="400"
          data-aos-duration="200"
          data-aos-once="true"
        >
          Tingkatkan kualitas layanan dan <br /> manajemen warnetmu dengan SBS
        </p>
        <Link to="/contactus">
          <button
            data-aos="fade-right"
            data-aos-delay="600"
            data-aos-duration="200"
            data-aos-once="true"
          >
            Kontak kami
          </button>
        </Link>
      </div>
      <div className="icon1">&nbsp;</div>
      <div className="icon2">&nbsp;</div>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  background: url(${iCafeFull}) no-repeat;
  background-size: cover;
  height: 978px;
  position: relative;

  div.main {
    position: absolute;
    left: 7.64%;
    top: 32.5%;
  }

  div.icon1 {
    background: url(${icon1}) no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 815px;
    left: 176px;
  }

  div.icon2 {
    background: url(${icon2}) no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 239px;
    left: 588px;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 52px;
    width: 49%;
    line-height: 110%;
  }
  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #808080;
    margin-top: 16px;
  }

  button {
    background: #009eff;
    padding: 20px 40px;
    box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.5);
    border-radius: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    font-size: 24px;
    box-sizing: border-box;
    color: white;
    margin-top: 28px;
  }

  @media only screen and (max-width: 600px) {
    background-size: cover;
    background-position: bottom;
    height: 685px;
    div.main {
      top: 12.7%;
      left: 8%;
    }
    h1 {
      font-size: 32px;
      width: 85%;
    }
    p {
      font-size: 14px;
    }
    button {
      font-size: 16px;
      padding: 12px 32px;
    }
    div.icon1 {
      top: 59%;
      left: 6.13%;
    }
    div.icon2 {
      top: 8.73%;
      left: 72.26%;
    }
  }
`;

export default Header;
