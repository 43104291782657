import React from "react";
import styled from "styled-components";
import RevenueIcon from "../../assets/images/revenue.svg";
import InfoIcon from "../../assets/images/info.svg";
import BillingIcon from "../../assets/images/billing.svg";
import FoodIcon from "../../assets/images/foods.svg";
import CashIcon from "../../assets/images/cash.svg";
import QrisIcon from "../../assets/images/qris.svg";
import { useSelector } from "react-redux";

const SummaryDetail = () => {
  const { revenue, revenueDetail } = useSelector((state) => state.summary);

  return (
    <SummaryWrapper id="summary">
      <div className="summary_item">
        <div className="icon icon_revenue">
          <img src={RevenueIcon} alt="" />
        </div>
        <div className="detail">
          <p className="desc">Pendapatan</p>
          <p className="amount">
            Rp{" "}
            {(parseInt(revenue.cash) + parseInt(revenue.qris)) / 1000000 >= 1
              ? ((parseInt(revenue.cash) + parseInt(revenue.qris)) / 1000000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "jt"
              : ((parseInt(revenue.cash) + parseInt(revenue.qris)) / 1000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "rb"}
          </p>
        </div>
        <div className="info">
          <img src={InfoIcon} alt="" />
          <div>
            <p>
              Pendapatan Rp{" "}
              {(parseInt(revenue.cash) + parseInt(revenue.qris))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </p>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="summary_item">
        <div className="icon icon_billing">
          <img src={BillingIcon} alt="" />
        </div>
        <div className="detail">
          <p className="desc">Billing</p>
          <p className="amount-secondary">
            Rp{" "}
            {revenueDetail.billing / 1000000 >= 1
              ? (revenueDetail.billing / 1000000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "jt"
              : (revenueDetail.billing / 1000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "rb"}
          </p>
        </div>
        <div className="info">
          <img src={InfoIcon} alt="" />
          <div>
            <table>
              <thead>
                <tr>
                  <th>Pemasukan</th>
                  <th>Refund</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Rp{" "}
                    {revenueDetail.billing / 1000000 >= 1
                      ? (revenueDetail.billing / 1000000)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                          .replace(".", ",") + "jt"
                      : (revenueDetail.billing / 1000)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                          .replace(".", ",") + "rb"}
                  </td>
                  <td>
                    Rp{" "}
                    {revenueDetail.refund / 1000000 >= 1
                      ? (revenueDetail.refund / 1000000)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                          .replace(".", ",") + "jt"
                      : (revenueDetail.refund / 1000)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                          .replace(".", ",") + "rb"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="summary_item">
        <div className="icon icon_food">
          <img src={FoodIcon} alt="" />
        </div>
        <div className="detail">
          <p className="desc">Food</p>
          <p className="amount-secondary">
            Rp{" "}
            {revenueDetail.food / 1000000 >= 1
              ? (revenueDetail.food / 1000000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "jt"
              : (revenueDetail.food / 1000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "rb"}
          </p>
        </div>
      </div>
      <div className="divider"></div>
      <div className="summary_item">
        <div className="icon icon_cash">
          <img src={CashIcon} alt="" />
        </div>
        <div className="detail">
          <p className="desc">Cash</p>
          <p className="amount-secondary">
            Rp{" "}
            {revenue.cash / 1000000 >= 1
              ? (revenue.cash / 1000000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "jt"
              : (revenue.cash / 1000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "rb"}
          </p>
        </div>
      </div>
      <div className="summary_item">
        <div className="icon icon_qris">
          <img src={QrisIcon} alt="" />
        </div>
        <div className="detail">
          <p className="desc">QRIS</p>
          <p className="amount-secondary">
            Rp{" "}
            {revenue.qris / 1000000 >= 1
              ? (revenue.qris / 1000000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "jt"
              : (revenue.qris / 1000)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  .replace(".", ",") + "rb"}
          </p>
        </div>
      </div>
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
  margin-top: 24px;
  display: flex;
  background: #25282e;
  border-radius: 24px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
  padding: 24px;
  div.summary_item {
    display: flex;
    flex-shrink: 0;
    margin-right: 56px;
    div.icon {
      /* background: rgba(158, 215, 250, 0.16); */
      /* box-shadow: 0px 6px 8px rgba(158, 215, 250, 0.08); */
      border-radius: 16px;
      width: 60px;
      height: 60px;
      margin-right: 16px;
      img {
        display: block;
        margin: auto;
        margin-top: 18px;
        width: 24px;
        height: 24px;
        /* box-shadow: 0px 4px 8px rgba(45, 175, 255, 0.24); */
      }
    }
    div.icon_revenue {
      background: rgba(158, 215, 250, 0.16);
      box-shadow: 0px 6px 8px rgba(158, 215, 250, 0.08);
      img {
        box-shadow: 0px 4px 8px rgba(45, 175, 255, 0.24);
      }
    }
    div.icon_billing {
      background: rgba(158, 235, 250, 0.16);
      box-shadow: 0px 6px 8px rgba(158, 215, 250, 0.08);
      img {
        box-shadow: 0px 4px 8px rgba(36, 255, 202, 0.24);
      }
    }
    div.icon_food {
      background: rgba(250, 197, 158, 0.16);
      img {
        box-shadow: 0px 4px 8px rgba(240, 101, 0, 0.24);
      }
    }
    div.icon_cash {
      background: rgba(158, 250, 228, 0.16);
      box-shadow: 0px 4px 8px rgba(36, 255, 202, 0.08);
      img {
        box-shadow: 0px 4px 8px rgba(36, 255, 202, 0.24);
      }
    }
    div.icon_qris {
      background: rgba(152, 112, 252, 0.16);
      box-shadow: 0px 4px 4px rgba(152, 112, 252, 0.08);
      img {
        box-shadow: 0px 4px 8px rgba(152, 112, 252, 0.24);
      }
    }
    div.detail {
      display: block;
      height: 60px;
      p.desc {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 600;
        color: #5c6366;
        font-size: 12px;
        margin-top: 2px;
      }
      p.amount,
      p.amount-secondary {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 24px;
        margin-top: 4px;
      }
      p.amount {
        color: #e5f8ff;
      }
      p.amount-secondary {
        color: #b0bec4;
      }
    }
    div.info {
      height: 60px;
      padding: 25px 0;
      margin-left: 16px;
      position: relative;
      img {
        cursor: pointer;
      }
      img:hover + div {
        opacity: 1;
      }
      div {
        opacity: 0;
        transition: opacity 0.5s;
        position: absolute;
        top: 60px;
        left: -60px;
        background: #2d3139;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 10px 16px;
        table th {
          font-family: "Nunito Sans", sans-serif;
          font-size: 12px;
          line-height: 16px;
          color: #b0bec4;
        }
        table th:first-child {
          padding-right: 24px;
        }
        table td,
        p {
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #e6f8ff;
        }
      }
    }
  }
  div.divider {
    background: #303436;
    border: 1px solid #303436;
    height: 60px;
    margin-right: 56px;
    border-radius: 2px;
  }
`;

export default SummaryDetail;
