import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { useSelector } from "react-redux";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);
Chart.defaults.color = "#FFF";

const LineChart = () => {
  const { analyticRevenue, type } = useSelector((state) => state.analytic);
  const date = new Date();
  const [data, setData] = useState([]);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];
  const [day, setDay] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [year, setYear] = useState([]);
  const [dataRevenue, setDataRevenue] = useState([]);

  useEffect(() => {
    if (Object.keys(analyticRevenue).length !== 0) {
      if (type == "daily") {
        let dayList = [];
        let dataList = [];
        Object.keys(analyticRevenue).forEach((key) => {
          dayList.push(key);
          dataList.push(analyticRevenue[key]);
        });
        setDay(dayList);
        setData(dataList);
      } else if (type == "monthly") {
        setData([
          parseInt(analyticRevenue.January ? analyticRevenue.January : 0),
          parseInt(analyticRevenue.February ? analyticRevenue.February : 0),
          parseInt(analyticRevenue.March ? analyticRevenue.March : 0),
          parseInt(analyticRevenue.April ? analyticRevenue.April : 0),
          parseInt(analyticRevenue.May ? analyticRevenue.May : 0),
          parseInt(analyticRevenue.June ? analyticRevenue.June : 0),
          parseInt(analyticRevenue.July ? analyticRevenue.July : 0),
          parseInt(analyticRevenue.August ? analyticRevenue.August : 0),
          parseInt(analyticRevenue.September ? analyticRevenue.September : 0),
          parseInt(analyticRevenue.October ? analyticRevenue.October : 0),
          parseInt(analyticRevenue.November ? analyticRevenue.November : 0),
          parseInt(analyticRevenue.December ? analyticRevenue.December : 0),
        ]);
      } else {
        let yearList = [];
        let dataList = [];
        Object.keys(analyticRevenue).forEach((key) => {
          yearList.push(key);
          dataList.push(analyticRevenue[key]);
        });
        setYear(yearList);
        setData(dataList);
      }
    } else {
      setDay([]);
      setData([]);
    }
  }, [analyticRevenue]);

  useEffect(() => {
    setDataMonth([]);
    for (let i = 0; i < date.getMonth() + 1; i++) {
      setDataMonth((e) => [...e, month[i]]);
    }
    setDataRevenue(data);
  }, [data]);

  return (
    <div>
      <Line
        data={{
          labels: type == "monthly" ? dataMonth : type == "daily" ? day : year,
          datasets: [
            {
              label: "# Penghasilan",
              data: dataRevenue,
              borderColor: "#009EFF",
            },
          ],
        }}
        height={400}
        width={600}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
                color: "#3A3E40",
              },
            },
            y: {
              min: 0,
              grid: {
                color: "#3A3E40",
                borderDash: [5],
              },
              ticks: {
                stepSize: 20000000,
                callback: function (label, index, labels) {
                  if (label == 0) return "0";
                  else if (label / 1000000 >= 1)
                    return "Rp " + label / 1000000 + "jt";
                  else return "Rp " + label / 1000 + "rb";
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
