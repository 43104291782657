import React, { useEffect } from "react";
import styled from "styled-components";
import UnsplashGrey from "../../assets/images/unsplash_grey.svg";
import ComputerIcon from "../../assets/images/computer.svg";
import ComputerOffIcon from "../../assets/images/computer-off.svg";
import HourGlass from "../../assets/images/hourglass-end 1.svg";
import { useState } from "react/cjs/react.development";
import Countdown from "react-countdown";
import { useGlobalContext } from "../../context/context";
import UnsplashBlue from "../../assets/images/unsplash_blue.svg";

// import "./Computer.css";

const Computer = ({ name, duration_time, status, updated_at, group }) => {
  const { computerGroupColor } = useGlobalContext();
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <>
        {days * 24 + hours}j {minutes}m
      </>
    );
  };
  // const checkRemainingTime = () => {
  //   if (
  //     new Date(updated_at).getTime() +
  //       new Date(duration_time * 60 * 1000).getTime() -
  //       new Date().getTime() >
  //     0
  //   )
  //     return true;
  //   else return false;
  // };
  return (
    <Wrapper
      className={
        "pt-4 pb-2.5 border-2 rounded-md relative cursor-pointer wrapper"
      }
      active={status === "online" ? "active" : ""}
    >
      <div
        className="absolute right-4 top-3 w-2.5 h-2.5 rounded-sm"
        style={{ background: computerGroupColor[group] }}
      >
        &nbsp;
      </div>
      <div>
        <img
          src={status === "online" ? ComputerIcon : ComputerOffIcon}
          alt=""
          className="w-4 mx-auto"
        />
        <div className="text-center text-base mt-1 font-black uppercase mx-auto w-20 computer-name">
          {name}
        </div>
      </div>
      {status === "online" ? (
        <div className="text-center rounded-md space-x-1 xs:space-x-3 lg:space-x-3 xl:space-x-1 2xl:space-x-2 mt-1 mx-auto w-20 h-6 relative computer-time">
          <img src={HourGlass} alt="" className="w-3 inline-block" />
          <p className="text-xs font-semibold inline-block">
            <Countdown
              date={
                // Date.now() +
                new Date(updated_at).getTime() +
                new Date(duration_time * 60 * 1000).getTime()
                // new Date().getTime()
              }
              renderer={renderer}
              daysInHours={true}
            />
          </p>
        </div>
      ) : (
        <div className="py-1 mt-1.5">&nbsp;</div>
      )}
      {name ? (
        <span className="tooltiptext invisible absolute -bottom-1/4 left-1/3 w-fit z-10 px-2">
          {name}
        </span>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-image: ${(props) =>
    props.active ? `url(${UnsplashBlue})` : `url(${UnsplashGrey})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-color: ${(props) => (props.active ? "#09a6c8" : "#93a4a7")};
  width: 100px;
  height: 100px;
`;

export default Computer;
