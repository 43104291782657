import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Back from "../assets/images/back.svg";

const ForgotPasswordForm = ({ setSuccess }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ status: false, msg: "" });

  async function handleSubmit(e) {
    e.preventDefault();
    if (!email) {
      setError({ status: true, msg: "Email harus diisi" });
    } else {
      setError({ status: false, msg: "" });
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/send-reset-password`,
          { email: email },
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => setSuccess(true))
        .catch((err) =>
          setError({ status: true, msg: "Email tidak terdaftar" })
        );
    }
  }

  return (
    <ForgotFormWrapper>
      <div className="title">
        <img
          src={Back}
          alt=""
          onClick={() => (window.location.href = "/login")}
          className="cursor-pointer"
        />
        <h1>Lupa password</h1>
      </div>
      <p>Masukkan email kamu untuk reset password</p>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={`error ${!error.status ? "hidden" : ""}`}>
            {error.msg}
          </div>
          <button>Kirim</button>
        </form>
      </div>
    </ForgotFormWrapper>
  );
};

const ForgotFormWrapper = styled.div`
  margin-top: 192px;
  /* margin-left: 160px; */
  div.title {
    position: relative;
    h1 {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      color: #e6f8ff;
    }
    img {
      position: absolute;
      top: 16px;
      left: -24.59px;
    }
  }
  p {
    font-family: "Nunito Sans", <sans-serif></sans-serif>;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #b0bec4;
    margin-top: 16px;
  }
  div.form {
    margin-top: 40px;
    input {
      background: #111518;
      border-radius: 6px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #b0bec4;
      padding: 20px;
      width: 400px;
    }
    button {
      padding: 18px 36px;
      width: 400px;
      height: 63px;
      background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
      box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.4);
      border-radius: 32px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #0c336e;
      margin-top: 48px;
    }
    div.error {
      font-family: "Nunito Sans", sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: #ed213a;
      margin-top: 5px;
    }
  }
`;

export default ForgotPasswordForm;
