import React from "react";
import styled from "styled-components";
import ResetImage from "../assets/images/reset-password-image.svg";

const ResetPassword = () => {
  return (
    <Wrapper>
      <ResetForm />
      <div className="image">
        <img src={ResetImage} alt="" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: linear-gradient(167.96deg, #1c1d1f 0%, #1b2125 100%);
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  div.image {
    margin-top: 192px;
    margin-left: 160px;
  }
`;

const ResetForm = () => {
  return (
    <ResetWrapper>
      <h1>Reset Password</h1>
      <p>
        Lengkapi kolom berikut untuk mengubah <br />
        password kamu
      </p>
      <form action="">
        <div>
          <input type="password" placeholder="Password baru" />
        </div>
        <div>
          <input type="password" placeholder="Ulangi password" />
        </div>
        <button type="submit">Simpan</button>
      </form>
    </ResetWrapper>
  );
};

const ResetWrapper = styled.div`
  margin-top: 192px;
  margin-left: 160px;
  h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: #e5f8ff;
  }
  p {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #b0bec4;
    margin-top: 16px;
  }
  form {
    margin-top: 40px;
    input {
      background: #111518;
      border-radius: 6px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #b0bec4;
      padding: 20px;
      width: 400px;
      margin-bottom: 24px;
    }
    button {
      margin-top: 24px;
      width: 400px;
      height: 63px;
      background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
      box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.4);
      border-radius: 32px;
      ont-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #0c336e;
    }
  }
`;

export default ResetPassword;
