import React from "react";
import styled from "styled-components";

const ForgotDone = ({ setSuccess }) => {
  return (
    <ForgotDoneWrapper>
      <h1>Link terkirim</h1>
      <p className="desc">
        Cek email kamu untuk mengakses link verifikasi reset password. Jika
        tidak ada, silahkan cek di folder spam.
      </p>
      <p className="resend">
        Belum menerima email?{" "}
        <span onClick={() => setSuccess(false)}>Kirim ulang</span>
      </p>
      <a href="/login" className="back">
        Kembali ke Login
      </a>
    </ForgotDoneWrapper>
  );
};

const ForgotDoneWrapper = styled.div`
  margin-top: 394px;
  margin-left: 160px;
  h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: #e6f8ff;
  }
  p.desc {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #b0bec4;
    width: 373px;
    margin-top: 16px;
  }
  p.resend {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #b0bec4;
    margin-top: 24px;
    span {
      font-weight: 700;
      color: #1f78ff;
      cursor: pointer;
    }
  }
  a.back {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #009eff;
    display: block;
    margin-top: 24px !important;
  }
`;

export default ForgotDone;
