import React, { useEffect } from "react";
import styled from "styled-components";
import SocialProofCard from "./SocialProofCard";
import Slider from "react-slick";
import ArrNext from "../../assets/images/arrow-next-dark.svg";
import ArrNextMob from "../../assets/images/arrow-next-dark-mobile.svg";
import ArrPrev from "../../assets/images/arrow-prev-dark.svg";
import ArrPrevMob from "../../assets/images/arrow-prev-dark-mobile.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SocialProofCardList = () => {
  const settings =
    window.innerWidth > 880
      ? window.innerWidth < 1000
        ? {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
          }
        : {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
          }
      : {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        };
  // var settings;
  // const cardCount = () => {
  //   if (window.innerWidth > 600 && window.innerWidth < 1000) {
  //     settings = {
  //       dots: false,
  //       infinite: true,
  //       speed: 500,
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //       arrows: true,
  //     };
  //   }
  //   console.log("a");
  // };
  // useEffect(() => {
  //   cardCount();
  // }, []);
  return (
    <Wrapper>
      <Slider {...settings}>
        <SocialProofCard />
        <SocialProofCard />
        <SocialProofCard />
        <SocialProofCard />
        <SocialProofCard />
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 115px;
  margin-top: 103px;
  position: relative;
  .slick-prev,
  .slick-next {
    border-radius: 8px;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 580px;
  }
  .slick-prev {
    left: 43%;
  }
  .slick-prev::before {
    content: url(${ArrPrev}) !important;
    opacity: 1 !important;
  }
  .slick-next {
    left: 52%;
  }
  .slick-next::before {
    content: url(${ArrNext}) !important;
    opacity: 1 !important;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 32px;
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;
      top: 330px;
    }
    .slick-prev {
      left: 35%;
    }
    .slick-next {
      left: 55%;
    }
    .slick-prev::before {
      content: url(${ArrPrevMob}) !important;
    }
    .slick-next::before {
      content: url(${ArrNextMob}) !important;
    }
  }
`;

export default SocialProofCardList;
