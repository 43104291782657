import React from "react";
import styled from "styled-components";
import Report2 from "../assets/images/lapkeu.svg";
import Line from "../assets/images/line.svg";
import Report1 from "../assets/images/report.svg";
import Account1 from "../assets/images/account1.svg";
import Account2 from "../assets/images/account2.svg";
import Qris1 from "../assets/images/qris1.svg";
import Qris2 from "../assets/images/qris2.svg";
import PC1 from "../assets/images/pc1.svg";
import PC2 from "../assets/images/pc2.svg";
import PlusDark from "../assets/images/plus-dark.svg";
import PlusLight from "../assets/images/plus-light.svg";
import ArrUp from "../assets/images/arrow-up.svg";
import ArrDown from "../assets/images/arrow-down.svg";
import { useState } from "react";
import Spark1 from "../assets/images/offer-bg-right.svg";
import Spark2 from "../assets/images/offer-bg-left.svg";
import OfferList from "./OfferList";

const Offer = () => {
  const [activeOffer, setActiveOffer] = useState(1);

  const handleClick = (n) => {
    if (window.innerWidth <= 600) {
      activeOffer !== n ? setActiveOffer(n) : setActiveOffer(0);
    } else setActiveOffer(n);
  };

  const data = [
    {
      title: "Laporan Keuangan",
      imgTitle: Report1,
      content1:
        "Banyak Pemilik warnet seperti kamu yang tidak mendapatkan laporan keuangan yang akurat sehingga keuntungan kamu berkurang dan mengalami kerugian.",
      content2:
        "Dengan adanya sistem pemasukan dan pengeluaran keuangan di SBS, kini kamu bisa melihat laporan keuangan dimanapun dan kapanpun, baik dalam harian, mingguan hingga bulanan hanya dengan mengakses smartphone kamu.",
      imgContent: Report2,
    },
    {
      title: "Satu akun untuk semua group PC",
      imgTitle: Account1,
      content1:
        "Kalau kamu tidak tau jumlah member yang tepat karena member memiliki beberapa akun pada tiap grup PC Client (Misalkan Reguler, VIP, dll); akan menghambat kamu mengelola warnet.",
      content2:
        "Karena itu, SBS membuat sistem satu akun member yang bisa digunakan di berbagai grup PC Client sehingga kamu bisa mengelola warnet dengan lebih mudah.",
      imgContent: Account2,
    },
    {
      title: "Pembayaran non-tunai",
      imgTitle: Qris1,
      content1:
        "Banyak warnet yang tidak menyediakan metode pembayaran non-tunai dan menyebabkan member kesulitan membayar jika mereka tidak membawa cash atau uang yang cukup, sehingga tidak bisa lanjut bermain.",
      content2:
        "Bayangkan dengan SBS, member melakukan pembayaran non-tunai melalui QRIS hanya dengan men-scan QR Code, semudah itu",
      imgContent: Qris2,
    },
    {
      title: "Cek ketersediaan PC",
      imgTitle: PC1,
      content1:
        "Banyak member merasa kecewa jika mereka sudah datang ke warnet tetapi tidak ada PC yang tersedia, sehingga mereka tidak jadi bermain dan kembali pulang.",
      content2:
        "Maka SBS menyediakan website dimana member bisa mengecek ketersediaan PC secara real-time dimanapun, kapanpun, mudah kan?",
      imgContent: PC2,
    },
  ];

  return (
    <Wrapper id="offer" data-aos="fade-in" data-aos-once="true">
      <div className="icon-line1">&nbsp;</div>
      <div className="icon-line2">&nbsp;</div>
      <div className="icon-plusdark">&nbsp;</div>
      <div className="icon-pluslight">&nbsp;</div>
      <div className="spark-right">&nbsp;</div>
      <div className="spark-left">&nbsp;</div>
      <h1>Apa yang SBS tawarkan</h1>
      <div className="mobile:grid mobile:grid-cols-2">
        <div>
          <ul>
            {data.map((value, index) => (
              <OfferList
                activeOffer={activeOffer}
                value={value}
                index={index}
                key={index}
                handleClick={handleClick}
              />
            ))}
          </ul>
        </div>
        <div>
          {data.map((value, index) => (
            <div
              className={
                activeOffer === index + 1 ? "content" : "content hidden"
              }
              key={"o" + index}
            >
              <img src={value.imgContent} alt="" />
              <p>{value.content1}</p>
              <p>{value.content2}</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 80px 110px 267px 110px;
  /* margin-bottom: 60px; */
  position: relative;
  div.icon-line1,
  div.icon-line2 {
    position: absolute;
    width: 200px;
    height: 1px;
  }
  div.icon-line1 {
    top: 0;
    left: 12.08%;
    background: url(${Line});
  }
  div.icon-line2 {
    background: url(${Line});
    bottom: 0;
    left: 12.08%;
  }
  div.icon-plusdark {
    background: url(${PlusDark});
    position: absolute;
    width: 16px;
    height: 16px;
    top: 31.03%;
    left: 88.54%;
  }
  div.icon-pluslight {
    background: url(${PlusLight});
    position: absolute;
    width: 24px;
    height: 24px;
    top: 85.18%;
    left: 43.68%;
  }
  div.spark-right {
    background: url(${Spark1}) no-repeat;
    background-size: cover;
    position: absolute;
    width: 27.77%;
    height: 400px;
    top: 0;
    right: 140px;
  }
  div.spark-left {
    background: url(${Spark2}) no-repeat;
    background-size: cover;
    position: absolute;
    width: 27.77%;
    height: 500px;
    bottom: 10px;
    left: 0;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 52px;
    color: #303030;
    margin-bottom: 140px;
  }
  li {
    width: 75.57%;
    height: 92px;
    padding: 16px;
    background: linear-gradient(0deg, #f5fdff, #f5fdff),
      linear-gradient(151.99deg, #303030 0%, #121212 100%);
    border-radius: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 32px;
    cursor: pointer;
    transition: width 0.8s, height 0.8s, padding 0.8s;
    position: relative;
    z-index: 5;
  }
  li:hover {
    padding: 32px;
    width: 80.82%;
    height: 128px;
  }
  li.active {
    width: 80.82%;
    height: 128px;
    background: linear-gradient(0deg, #e5faff, #e5faff),
      linear-gradient(151.99deg, #303030 0%, #121212 100%);
    border: 2px solid #009eff;
    padding: 32px;
    font-weight: 800;
  }
  li.accordion {
    display: none;
  }
  li.img {
    /* width: 12.17%; */
  }
  div.content {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
  }
  @keyframes fadeIn {
    0% {
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  p {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-top: 30px;
  }

  @media only screen and (max-width: 600px) {
    padding: 40px 8% 40px 8%;
    div.spark-left {
      display: none;
    }
    div.spark-right {
      display: none;
    }
    div.icon-line1,
    div.icon-line2 {
      left: 8%;
      width: 100px;
    }
    div.icon-plusdark {
      left: 95%;
      width: 10px;
      height: 10px;
      background-size: 100%;
    }
    div.icon-pluslight {
      top: 90%;
      left: 10px;
      width: 16px;
      height: 16px;
      background-size: 100%;
      overflow: hidden;
    }
    h1 {
      font-size: 32px;
      margin-bottom: 40px;
    }
    li:not(.accordion) {
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      transition: none;
      position: relative;
    }
    li:not(.accordion):hover {
      padding: 14px;
      width: 100%;
      height: 100%;
    }
    li.active {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #e5faff, #e5faff),
        linear-gradient(151.99deg, #303030 0%, #121212 100%);
      border: 2px solid #009eff;
      padding: 14px;
      font-weight: 800;
    }
    li:not(.accordion) img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    li:not(.accordion)::after {
      content: url(${ArrDown});
      position: absolute;
      right: 14px;
    }
    li.active:not(.accordion)::after {
      content: url(${ArrUp});
    }
    li.accordion {
      display: block;
      text-align: justify;
      width: 100%;
      height: 0px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      background: white;
      transition: height 0.8s;
      /* animation: stretchUp 0.8s; */
      border-radius: 0;
    }
    @keyframes stretchDown {
      0% {
        height: 0px;
        opacity: 0;
      }
      100% {
        height: 430.984px;
        opacity: 1;
      }
    }
    @keyframes stretchUp {
      0% {
        opacity: 1;
        height: 430.984px;
      }
      100% {
        opacity: 0;
        height: 0px;
      }
    }

    li.accordion.show {
      display: block;
      overflow: hidden;
      height: 430.984px;
      margin-top: 16px;
      margin-bottom: 16px;
      padding-bottom: 16px;
      transition: height 0.8s;
      /* animation: stretchDown 0.8s; */
    }
    li.accordion img {
      width: 53%;
      margin: 0 auto;
    }
    li.accordion p {
      font-size: 14px;
    }
    div.content {
      display: none;
    }
  }
`;

export default Offer;
