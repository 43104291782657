import React from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  Navbar,
  ContactUsForm,
  ContactUsModal,
  ContactUsSuccess,
} from "../components";

const ContactUs = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  function submitForm() {
    setIsSubmitted(true);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <Wrapper>
      <Navbar />
      <ContactUsForm
        openModal={openModal}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        values={values}
        setValues={setValues}
      />
      <ContactUsModal
        values={values}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        setIsSuccess={setIsSuccess}
      />
      <ContactUsSuccess
        isSuccess={isSuccess}
        openModal={openModal}
        closeModal={closeModal}
      />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
`;

export default ContactUs;
