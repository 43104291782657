import React, { useState } from "react";
import { useGlobalContext } from "../../context/context";
import "./GroupDescription.css";

const GroupDescription = () => {
  const { computerGroups, computerGroupColor } = useGlobalContext();
  const [isActive, setIsActive] = useState(false);
  const openModal = () => {
    setIsActive(true);
  };
  const closeModal = () => {
    setIsActive(false);
  };
  return (
    <>
      <fieldset
        className="border border-solid px-6 py-4 rounded-md"
        id="side-fieldset"
      >
        <legend className="font-semibold">Keterangan</legend>
        <p className="mb-1">Status</p>
        <ul id="status">
          <li className="mb-1">Dipakai</li>
          <li className="mb-1">Tersedia</li>
        </ul>
        <hr className="my-2" />
        <p className="mb-1">Grup</p>
        <ul id="group">
          {computerGroups.map((group, index) => {
            return (
              <li className="mb-1" key={index}>
                <div
                  className="w-3 h-3 inline-block align-middle mr-1.5 rounded-sm"
                  style={{ background: computerGroupColor[group.name] }}
                ></div>
                {group.name}
              </li>
            );
          })}
        </ul>
      </fieldset>
      <div className="mb-2 description hidden" onClick={() => openModal()}>
        Keterangan
      </div>
      <div className={isActive ? "overlay-layer" : "overlay-layer hidden"}>
        <fieldset
          className="border border-solid px-6 py-4 rounded-md mx-auto"
          id="overlay-fieldset"
        >
          <legend className="font-semibold">Keterangan</legend>
          <p className="mb-1">Status</p>
          <ul id="status">
            <li className="mb-1">Dipakai</li>
            <li className="mb-1">Tersedia</li>
          </ul>
          <hr className="my-2" />
          <p className="mb-1">Grup</p>
          <ul id="group">
            {computerGroups.map((group, index) => {
              return (
                <li className="mb-1" key={index}>
                  <div
                    className="w-3 h-3 inline-block align-middle mr-1.5 rounded-sm"
                    style={{ background: computerGroupColor[group.name] }}
                  ></div>
                  {group.name}
                </li>
              );
            })}
          </ul>
        </fieldset>
        <div className="w-min mx-auto close-modal" onClick={() => closeModal()}>
          OK
        </div>
      </div>
    </>
  );
};

export default GroupDescription;
