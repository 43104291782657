import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Eye from "../assets/images/eye.svg";

const ChangePassword = ({ setShowCP }) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [error, setError] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handlePassword = (e) => {
    setPassword(e);
  };
  const handleNewPassword = (e) => {
    setNewPassword(e);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e);
  };
  const handleSubmit = async () => {
    setError({ password: "", newPassword: "", confirmPassword: "" });
    if (password === "" || newPassword === "" || confirmPassword == "") {
      if (password === "") {
        setError((error) => ({ ...error, password: "Password harus diisi" }));
      }
      if (newPassword === "") {
        setError((error) => ({
          ...error,
          newPassword: "Password baru harus diisi",
        }));
      }
      if (confirmPassword === "") {
        setError((error) => ({
          ...error,
          confirmPassword: "Konfirmasi password harus diisi",
        }));
      }
    } else if (newPassword !== confirmPassword) {
      setError((error) => ({
        ...error,
        confirmPassword: "Konfirmasi password tidak sesuai",
      }));
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/change-password`,
          {
            old_password: password,
            new_password: newPassword,
            new_password_confirmation: confirmPassword,
          },
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => setShowCP(false))
        .catch((err) => {
          console.log(err.response.data);
          if (err.response.data) {
            if (err.response.data.errors) {
              if (err.response.data.errors.new_password) {
                if (
                  err.response.data.errors.new_password[0].includes(
                    "characters"
                  )
                ) {
                  setError((error) => ({
                    ...error,
                    newPassword: "Password minimal 6 karakter",
                  }));
                } else {
                  setError((error) => ({
                    ...error,
                    newPassword:
                      "Password baru tidak boleh sama dengan password lama",
                  }));
                }
              }
            } else if (err.response.data.message.includes("old password")) {
              setError((error) => ({
                ...error,
                password: "Password salah",
              }));
            }
          }
        });
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowCP(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <Wrapper>
      <div className="modal">
        <h1>Ubah password</h1>
        <div className="input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password sekarang"
            onChange={(e) => handlePassword(e.target.value)}
          />
          <img
            src={Eye}
            alt=""
            onClick={() => setShowPassword(!showPassword)}
          />
          {error.password && <div className="error">{error.password}</div>}
        </div>
        <div className="input">
          <input
            type={showNewPassword ? "text" : "password"}
            placeholder="Password baru"
            onChange={(e) => handleNewPassword(e.target.value)}
          />
          <img
            src={Eye}
            alt=""
            onClick={() => setShowNewPassword(!showNewPassword)}
          />
          {error.newPassword && (
            <div className="error">{error.newPassword}</div>
          )}
        </div>
        <div className="input">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Ulangi password"
            onChange={(e) => handleConfirmPassword(e.target.value)}
          />
          <img
            src={Eye}
            alt=""
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
          {error.confirmPassword && (
            <div className="error">{error.confirmPassword}</div>
          )}
        </div>
        <button className="active" onClick={handleSubmit}>
          Simpan
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* background: linear-gradient(167.96deg, #1c1d1f 0%, #1b2125 100%); */
  background: linear-gradient(167.96deg, #1c1d1f 0%, rgba(27, 33, 37, 0.8));
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  div.modal {
    background: #2d3139;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    width: 560px;
    height: 570px;
    margin: auto;
    margin-top: 227px;
    padding: 80px;
    /* position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */

    h1 {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: #ffffff;
      margin-bottom: 24px;
    }
    div.error {
      font-family: "Nunito Sans", sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: #ed213a;
    }
    div.input {
      position: relative;
      input {
        margin-top: 16px;
        background: #111518;
        border-radius: 6px;
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #b0bec4;
        padding: 20px;
        width: 100%;
      }
      img {
        position: absolute;
        top: 36.25px;
        right: 24.25px;
        cursor: pointer;
      }
    }
    button {
      padding: 18px 36px;
      margin-top: 40px;
      width: 100%;
      background: #282c2f;
      border-radius: 32px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #5c6366;
    }
    button.active {
      background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
      box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.4);
      color: #0c336e;
    }
  }
`;

export default ChangePassword;
