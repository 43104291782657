import React from "react";
import styled from "styled-components";
import { month } from "../utils/monthName";

const SpecificItem = ({ image, title, amount, time }) => {
  return (
    <SpecificItemWrapper>
      <div
        className={`spec-image ${
          title === "Pemasukan Food tertinggi" ? "spec-image-food" : ""
        }`}
      >
        <img src={image} alt="" />
      </div>
      <div className="spec-detail">
        <div className="detail-title">{title}</div>
        <div className="detail-amount">
          Rp{" "}
          {amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0}
        </div>
        {time && (
          <div className="detail-time">
            Pada {parseInt(time[2])} {month[parseInt(time[1]) - 1]} {time[0]}{" "}
            <a href="#">Lihat detail</a>
          </div>
        )}
      </div>
    </SpecificItemWrapper>
  );
};

const SpecificItemWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  margin-right: 56px;
  div.spec-image {
    width: 60px;
    height: 60px;
    background: rgba(158, 235, 250, 0.16);
    box-shadow: 0px 6px 8px rgba(158, 235, 250, 0.08);
    border-radius: 16px;
    margin-right: 16px;

    img {
      width: 24px;
      height: 24px;
      margin: auto;
      margin-top: 18px;
    }
  }
  div.spec-detail {
    div.detail-title {
      font-family: "Nunito Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: #b0bec4;
      line-height: 16px;
    }
    div.detail-amount {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #e5f8ff;
      margin-top: 8px;
    }
    div.detail-time {
      font-family: "Nunito Sans", sans-serif;
      font-size: 10px;
      line-height: 14px;
      margin-top: 8px;
      color: #b0bec4;
      a {
        margin-left: 8px;
        font-weight: 700;
        font-size: 10px;
        color: #009eff;
      }
    }
  }

  .spec-image-food {
    background: rgba(250, 197, 158, 0.16) !important;
    box-shadow: 0px 6px 8px rgba(250, 197, 158, 0.08);
  }
`;

export default SpecificItem;
