import React from "react";
import styled from "styled-components";
import "./SocialProof.css";
import MockAvatar from "../../assets/images/mock-avatar.svg";

const SocialProofCard = () => {
  return (
    <Wrapper>
      <img src={MockAvatar} alt="" className="card-img rounded-lg" />
      <div className="card-title">Mega Lodon</div>
      <div className="card-subtitle">Gamer akut, Sumatera Timur</div>
      <p className="card-description">
        Lorem ipsum dolor sit ame, consectetur adipiscing elit. ut enim
        condimentum duis eget. Vestibulum eu lorem consequat porttitor risus
        nec.
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 94.52%;
  /* width: 380px; */
  height: 521px;
  background: linear-gradient(0deg, #f5fdff, #f5fdff),
    linear-gradient(151.99deg, #303030 0%, #121212 100%);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 48px;
  margin-bottom: 20px !important;
  box-shadow: 0px 10px 5px lightgrey;
  img {
    width: 100px;
    height: 100px;
  }
  div.card-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #29d1f5;
    margin-top: 30px;
  }

  div.card-subtitle {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    margin-top: 4px;
  }
  p.card-description {
    font-family: "Nunito Sans", sans-serif;
    margin-top: 24px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 288px;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
    margin: 0 !important;
    img {
      width: 50px;
      height: 50px;
    }
    div.card-title {
      font-size: 14px;
      margin-top: 12px;
    }
    div.card-subtitle {
      font-size: 10px;
    }
    p.card-description {
      margin-top: 12px !important;
      font-size: 12px;
    }
  }
`;

export default SocialProofCard;
