import React from "react";
import styled from "styled-components";

const AboutUs = ({ image, content }) => {
  return (
    <Wrapper>
      <div className="secondary">
        <img src={image} alt="" className="mx-auto" />
        <p>{content}</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  div.secondary {
    margin-top: 160px;
  }
  div.secondary p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    /* font-size: 40px; */
    font-size: 2.78vw;
    margin-left: 11.18%;
    width: 44.44%;
  }
`;

export default AboutUs;
