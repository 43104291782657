import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import Eye from "../assets/images/eye.svg";
import Logo from "../assets/images/logo-sbs-blur.svg";

const LoginForm = () => {
  const [value, setValue] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const passwordInput = useRef(null);

  const data = {
    username: value.email,
    password: value.password,
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    scope: "*",
  };

  function handleLogin() {
    setError("");
    if (!value.email || !value.password) {
      setError("Email/Password harus diisi");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_OAUTH}oauth/token`, data)
      .then((res) => {
        localStorage.setItem("token", res.data.access_token);
        window.location.href = `/owner/dashboard`;
      })
      .catch((err) => {
        console.log(err);
        setError("Email/Password salah!");
      });
  }

  async function getUserInfo() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        window.location.href = `/owner/dashboard`;
      })
      .catch((err) => {});
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  function togglePassword() {
    passwordInput.current.type === "text"
      ? (passwordInput.current.type = "password")
      : (passwordInput.current.type = "text");
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <LoginFormWrapper>
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <h1>Dashboard Owner</h1>
      <p>Silahkan login dengan mengisi kolom berikut</p>
      <form onSubmit={handleSubmit}>
        <div className="error">{error}</div>
        <div>
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => setValue({ ...value, email: e.target.value })}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            ref={passwordInput}
            onChange={(e) => setValue({ ...value, password: e.target.value })}
          />
          <img
            src={Eye}
            alt=""
            onClick={togglePassword}
            className="cursor-pointer"
          />
        </div>
        <button onClick={() => handleLogin()}>Login</button>
      </form>
      <a href="/forgot-password">Lupa Password?</a>
    </LoginFormWrapper>
  );
};

const LoginFormWrapper = styled.div`
  margin-top: 192px;
  /* margin-left: 11.11%; */
  div.logo {
    padding-right: 100px;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: #e6f8ff;
  }
  p {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #b0bec4;
  }
  form {
    margin-top: 40px;
    div.error {
      font-family: "Nunito Sans", sans-serif;
      color: red;
      margin-bottom: 10px;
    }
    div {
      position: relative;
      width: 400px;
      img {
        position: absolute;
        top: 24.25px;
        right: 24px;
      }
    }

    input {
      background: #111518;
      border-radius: 6px;
      width: 400px;
      height: 62px;
      padding: 20px;
      margin-bottom: 24px;
      color: #b0bec4;
    }
    button {
      background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
      box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.4);
      border-radius: 32px;
      width: 400px;
      height: 63px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #0c336e;
      padding: 18px;
      box-sizing: border-box;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  a {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1f78ff;
  }
`;

export default LoginForm;
