import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FilterIcon from "../assets/images/filter-range.svg";
import FilterIconLight from "../assets/images/filter-range-light.svg";
import ArrNext from "../assets/images/arrow-next-filter.svg";
import ArrPrev from "../assets/images/arrow-prev-filter.svg";
import { DateRange } from "react-date-range";
import { id } from "date-fns/locale";
import { useDispatch } from "react-redux";
import {
  changeType,
  changeYear,
  changeMonth,
  getSummary,
  changeStartDate,
  changeEndDate,
  changeDay,
  getSummaryDetail,
} from "../features/summary/summarySlice";

const FilterSummary = ({ selectedRange, setSelectedRange }) => {
  const date = new Date();
  const dispatch = useDispatch();
  const [monthFilter, setMonthFilter] = useState(date.getMonth());
  const [yearFilter, setYearFilter] = useState(date.getFullYear());
  const [monthFilterShow, setMonthFilterShow] = useState(false);
  const [dateFilterShow, setDateFilterShow] = useState(false);
  const [filterDate, setFilterDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  const getRevenue = (type) => {
    switch (type) {
      case "this_month":
        setSelectedRange(1);
        dispatch(changeType("month"));
        dispatch(changeYear(date.getFullYear()));
        dispatch(changeMonth(date.getMonth() + 1));
        dispatch(getSummary());
        dispatch(getSummaryDetail());
        break;
      case "last_month":
        setSelectedRange(2);
        dispatch(changeType("month"));
        dispatch(changeMonth(date.getMonth() === 0 ? 12 : date.getMonth()));
        dispatch(getSummary());
        dispatch(getSummaryDetail());
        break;
      case "this_week":
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + (7 - date.getDay()));
        setSelectedRange(3);
        dispatch(changeType("week"));
        dispatch(
          changeStartDate(
            date.getDay() - 1 !== 0
              ? `${date.getFullYear()}-${date.getMonth() + 1}-${
                  date.getDate() - (date.getDay() - 1)
                }`
              : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
          )
        );
        dispatch(
          changeEndDate(
            7 - date.getDay() !== 0
              ? `${endDate.getFullYear()}-${
                  endDate.getMonth() + 1
                }-${endDate.getDate()}`
              : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
          )
        );
        dispatch(getSummary());
        dispatch(getSummaryDetail());
        break;
      case "today":
        setSelectedRange(4);
        dispatch(changeType("date"));
        dispatch(
          changeDay(
            `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
          )
        );
        dispatch(getSummary());
        dispatch(getSummaryDetail());
        break;
      default:
        break;
    }
  };

  const getFilterMonthRev = (month) => {
    setMonthFilter(month);
    dispatch(changeType("month"));
    dispatch(changeMonth(month + 1));
    dispatch(changeYear(yearFilter));
    dispatch(getSummary());
    dispatch(getSummaryDetail());
  };

  const getFilterYearRev = (year) => {
    setYearFilter(year);
  };

  const getFilterDateRev = (ranges) => {
    setFilterDate([ranges.selection]);
    dispatch(changeType("week"));
    dispatch(
      changeStartDate(
        `${ranges.selection.startDate.getFullYear()}-${
          ranges.selection.startDate.getMonth() + 1
        }-${ranges.selection.startDate.getDate()}`
      )
    );
    dispatch(
      changeEndDate(
        `${ranges.selection.endDate.getFullYear()}-${
          ranges.selection.endDate.getMonth() + 1
        }-${ranges.selection.endDate.getDate()}`
      )
    );
    dispatch(getSummary());
    dispatch(getSummaryDetail());
  };

  useEffect(() => {
    const ignoreClickOnElement = document.querySelector(".select-month");
    const ignoreClickOnElement2 = document.querySelector(
      ".filter-range-item-month"
    );

    const ignoreClickOnElement3 = document.querySelector("#date-range");
    const ignoreClickOnElement4 = document.querySelector(
      ".filter-range-item-date"
    );

    window.addEventListener("click", function (e) {
      const isClickInsideElement = ignoreClickOnElement.contains(e.target);
      const isClickInsideElement2 =
        e.target === ignoreClickOnElement2 ? true : false;
      if (!isClickInsideElement && !isClickInsideElement2) {
        setMonthFilterShow(false);
      }

      const isClickInsideElement3 = ignoreClickOnElement3.contains(e.target);
      const isClickInsideElement4 = ignoreClickOnElement4.contains(e.target);
      if (!isClickInsideElement3 && !isClickInsideElement4) {
        setDateFilterShow(false);
      }
    });
  }, []);

  return (
    <FilterWrapper className="filter-summary">
      <div className="title">Ringkasan</div>; ;
      <ul className="filter">
        <li
          className={selectedRange === 1 ? "active" : ""}
          onClick={() => getRevenue("this_month")}
        >
          Bulan ini
        </li>
        <li
          className={selectedRange === 2 ? "active" : ""}
          onClick={() => getRevenue("last_month")}
        >
          Bulan lalu
        </li>
        <li
          className={selectedRange === 3 ? "active" : ""}
          onClick={() => getRevenue("this_week")}
        >
          Minggu ini
        </li>
        <li
          className={selectedRange === 4 ? "active" : ""}
          onClick={() => getRevenue("today")}
        >
          Hari ini
        </li>
      </ul>
      <div className="filter-range">
        <div className="filter-month">
          <div
            className={`filter-range-item filter-range-item-month ${
              selectedRange === 5 ? "active" : ""
            }`}
            onClick={() => {
              setMonthFilterShow(!monthFilterShow);
              setSelectedRange(5);
              getFilterMonthRev(monthFilter);
            }}
          >
            {selectedRange == 5
              ? `${monthName[monthFilter]}, ${yearFilter}`
              : "Pilih bulan"}
          </div>
          <div
            className={`${
              monthFilterShow === true ? "select-month" : "hide select-month"
            }`}
          >
            <div className="year-list">{yearFilter}</div>
            <div className="filter-button">
              <img
                src={ArrPrev}
                alt=""
                onClick={() => getFilterYearRev(yearFilter - 1)}
              />
              <img
                src={ArrNext}
                alt=""
                onClick={() => getFilterYearRev(yearFilter + 1)}
              />
            </div>
            <div className="month-list">
              <div
                className={`month-list-item ${
                  monthFilter === 0 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(0)}
              >
                Jan
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 1 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(1)}
              >
                Feb
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 2 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(2)}
              >
                Mar
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 3 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(3)}
              >
                Apr
              </div>
            </div>
            <div className="month-list">
              <div
                className={`month-list-item ${
                  monthFilter === 4 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(4)}
              >
                Mei
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 5 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(5)}
              >
                Jun
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 6 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(6)}
              >
                Jul
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 7 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(7)}
              >
                Agu
              </div>
            </div>
            <div className="month-list">
              <div
                className={`month-list-item ${
                  monthFilter === 8 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(8)}
              >
                Sep
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 9 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(9)}
              >
                Okt
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 10 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(10)}
              >
                Nov
              </div>
              <div
                className={`month-list-item ${
                  monthFilter === 11 ? "active" : ""
                }`}
                onClick={() => getFilterMonthRev(11)}
              >
                Des
              </div>
            </div>
          </div>
        </div>
        <div className="filter-date">
          <div
            className={`filter-range-item filter-range-item-date ${
              selectedRange === 6 ? "active" : ""
            }`}
            onClick={() => {
              setSelectedRange(6);
              setDateFilterShow(!dateFilterShow);
            }}
          >
            {selectedRange == 6
              ? `${filterDate[0].startDate.getDate()} ${
                  monthName[filterDate[0].startDate.getMonth()]
                } ${filterDate[0].startDate.getFullYear()} - ${filterDate[0].endDate.getDate()} ${
                  monthName[filterDate[0].endDate.getMonth()]
                } ${filterDate[0].endDate.getFullYear()}`
              : "Pilih tanggal"}
          </div>
          <div id="date-range" className={!dateFilterShow ? "hidden" : ""}>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => getFilterDateRev(item)}
              moveRangeOnFirstSelection={false}
              ranges={filterDate}
              id="inputDate"
              locale={id}
            />
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  box-sizing: border-box !important;
  div.title {
    font-family: "Nunito Sans", sans-serif;
    color: #b0bec4;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  }
  ul.filter {
    margin-left: 48px;
    li {
      display: inline-block;
      padding: 6px 20px;
      border: 1px solid #b0bec4;
      font-family: "Nunito Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      box-sizing: border-box;
      border-radius: 16px;
      color: #b0bec4;
      margin-right: 16px;
      line-height: 16px;
      height: 31px;
    }
    li.active,
    li:hover {
      cursor: pointer;
      background: #1f78ff;
      border: 1px solid #1f78ff;
      color: #ffffff;
    }
  }
  div.filter-range {
    margin-left: 48px;
    div.filter-month,
    div.filter-date {
      display: inline-block;
    }
    div.filter-month {
      position: relative;
      div.select-month.hide {
        display: none;
      }
      div.select-month {
        position: absolute;
        z-index: 99;
        top: 40px;
        left: -45px;
        background: #2d3139;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        width: 212px;
        padding: 24px;
        div.filter-button {
          display: inline-block;
          margin-left: 90px;
          img {
            display: inline-block;
            margin-left: 13.36px;
            cursor: pointer;
          }
        }
        div.year-list {
          display: inline-block;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
          font-size: 12px;
          color: #e5f8ff;
        }
        div.month-list {
          margin-top: 12px;
          div.month-list-item {
            display: inline-block;
            font-family: "Nunito Sans", sans-serif;
            font-size: 14px;
            line-height: 16px;
            color: #b0bec4;
            cursor: pointer;
            width: 23px;
          }
          div.month-list-item:not(:last-child) {
            margin-right: 24px;
          }
          div.month-list-item.active {
            font-weight: 700;
            color: #1f78ff;
          }
        }
      }
    }
    div.filter-date {
      position: relative;
      div#date-range {
        position: absolute;
        left: 0;
        top: 40px;
        div.rdrCalendarWrapper {
          position: relative;
          z-index: 100;
          background: #2d3139;
          .rdrDateDisplayWrapper {
            background: #2d3139;
          }
          .rdrDay span {
            color: #b0bec4;
          }
          .rdrDayPassive span {
            color: #5c6366;
          }
          span.rdrMonthPicker select,
          span.rdrYearPicker select {
            color: #b0bec4;
          }
          span.rdrDateInput input {
            background: #111518;
            color: #b0bec4;
          }
        }
      }
    }
    div.filter-range-item {
      padding: 6px 20px;
      border: 1px solid #b0bec4;
      font-family: "Nunito Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      box-sizing: border-box;
      border-radius: 16px;
      color: #b0bec4;
      margin-right: 16px;
      line-height: 16px;
      height: 31px;
    }
    div.filter-range-item.active,
    div.filter-range-item:hover {
      cursor: pointer;
      background: #1f78ff;
      border: 1px solid #1f78ff;
      color: #ffffff;
    }
  }
  div.filter-range div.filter-range-item::before {
    content: url(${FilterIcon});
    margin-right: 10px;
  }
  div.filter-range div.filter-range-item.active::before,
  div.filter-range div.filter-range-item:hover::before {
    content: url(${FilterIconLight});
  }
`;

export default FilterSummary;
