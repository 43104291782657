import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  outlet: [],
  selected_outlet_id: "",
  selected_outlet_uuid: "",
  selected_outlet_name: "",
};

export const getOutlet = createAsyncThunk(
  "outlet/getOutlet",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/user-outlets`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.log(err));
  }
);

const outletSlice = createSlice({
  name: "outlet",
  initialState,
  reducers: {
    changeSelectedOutletId: (state, { payload }) => {
      state.selected_outlet_id = payload;
    },
    changeSelectedOutletUuid: (state, { payload }) => {
      state.selected_outlet_uuid = payload;
    },
    changeSelectedOutletName: (state, { payload }) => {
      state.selected_outlet_name = payload;
    },
  },
  extraReducers: {
    [getOutlet.fulfilled]: (state, action) => {
      if (action.payload !== null) {
        state.outlet = action.payload;
        state.selected_outlet_id = action.payload.data[0].id;
        state.selected_outlet_uuid = action.payload.data[0].outlet_uuid;
        state.selected_outlet_name = action.payload.data[0].name;
      }
    },
  },
});

export const {
  changeSelectedOutletId,
  changeSelectedOutletName,
  changeSelectedOutletUuid,
} = outletSlice.actions;

export default outletSlice.reducer;
