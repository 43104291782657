import React from "react";

const DetailTransactionOwnerDate = ({ month, date }) => {
  return (
    <tr className="title">
      <td colSpan={6}>
        {date.split("-")[2] +
          " " +
          month[parseInt(date.split("-")[1]) - 1] +
          " " +
          date.split("-")[0]}
      </td>
    </tr>
  );
};

export default DetailTransactionOwnerDate;
