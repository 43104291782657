export default function validateInfo(values) {
  let errors = {};

  //Name
  if (!values.name.trim()) {
    errors.name = "Isi nama kamu ya";
  }

  //Email
  if (!values.email) {
    errors.email = "Isi email kamu ya";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = "Email tidak valid";
  }

  //Phone number
  // const re = /^[0-9\b]+$/;
  if (!values.phone) {
    errors.phone = "Isi What'sApp / Nomor handphone kamu ";
  }
  //  else if (re.test(values.phone)) {
  //   errors.phone = "Nomor handphone tidak valid";
  // }

  //Address
  if (!values.address) {
    errors.address = "Isi alamat warnet kamu ya";
  }

  return errors;
}
