import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import getIp from "../utils/ip";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [computers, setComputers] = useState([]);
  const [computerGroups, setComputerGroups] = useState([]);
  const [computerGrouping, setComputerGrouping] = useState("Semua");
  const [computerGroupColor, setComputerGroupColor] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState("61933cef00e18");
  const [sort, setSort] = useState("nama");
  const [sorting, setSorting] = useState("Naik");

  const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });

  const firestore = getFirestore();
  let computerDataUnsubscribe;

  const computerData = doc(firestore, `computer-status/${selectedOutletId}`);
  function listenToADocument() {
    computerDataUnsubscribe = onSnapshot(computerData, (docSnapshot) => {
      if (docSnapshot.exists()) {
        getComputers(selectedOutletId);
      }
    });
  }

  // function cancelMyListenerAtTheAppropriateTime() {
  //   computerDataUnsubscribe();
  // }

  useEffect(() => {
    getOutlets();
    listenToADocument();
    getComputerGroups();
  }, []);

  useEffect(() => {
    getComputerGroups();
  }, [selectedOutletId]);

  useEffect(() => {
    getComputers(selectedOutletId);
  }, [sort, sorting, selectedOutletId]);

  const getComputerGroups = async () => {
    const computerGroupData = doc(
      firestore,
      `computer-group/${selectedOutletId}`
    );

    try {
      onSnapshot(computerGroupData, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const computerGroup = [];
          const groupColor = {};
          const docData = docSnapshot.data();

          for (var detail in docData) {
            computerGroup.push({
              name: detail,
            });
            groupColor[detail] =
              "#" + Math.floor(Math.random() * 16777).toString(16);
          }
          setComputerGroups(computerGroup);
          setComputerGroupColor(groupColor);
        } else {
          setComputerGroups([]);
        }
      });
    } catch (error) {}
  };

  const getComputers = async () => {
    // const computerData = doc(firestore, `computer-status/${selectedOutletId}`);
    setIsLoading(true);

    try {
      onSnapshot(computerData, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const computer = [];
          const docData = docSnapshot.data();

          for (var detail in docData) {
            computer.push({
              name: detail,
              status: docData[detail].status,
              duration_time: docData[detail].duration,
              updated_at: docData[detail].updated_at,
              group: docData[detail].group,
            });
          }
          setComputers(computer);
        } else {
          setComputers([]);
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getOutlets = async () => {
    try {
      let ip = await getIp();
      await axios
        .get(`${process.env.REACT_APP_API_URL}/outlets`)
        .then(function (response) {
          if (ip !== "110.232.81.154") {
            console.log(getIp());
            setOutlets(response.data.filter((a) => a.name !== "Tes Outlet"));
          } else setOutlets(response.data);
        });
    } catch (error) {}
  };
  return (
    <AppContext.Provider
      value={{
        isLoading,
        computers,
        computerGroups,
        computerGrouping,
        setComputerGrouping,
        computerGroupColor,
        outlets,
        getComputers,
        sort,
        setSort,
        sorting,
        setSorting,
        setSelectedOutletId,
        selectedOutletId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
