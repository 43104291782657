import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Room from "../assets/images/room-isometric.svg";
import PlusDark from "../assets/images/plus-dark.svg";
import PlusLight from "../assets/images/plus-light.svg";

const Footer = () => {
  return (
    <Wrapper data-aos="fade-in" data-aos-once="true">
      <div className="plus-dark">&nbsp;</div>
      <div className="plus-light">&nbsp;</div>
      <img src={Room} alt="" className="mx-auto" />
      <h1>Coba sekarang!</h1>
      <p>Masa depan warnetmu dimulai dari sini</p>
      <Link to="/contactus">
        <button className="block mx-auto">Kontak kami</button>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  padding-top: 280px;
  padding-bottom: 280px;
  position: relative;
  div.plus-dark {
    background: url(${PlusDark});
    width: 16px;
    height: 16px;
    position: absolute;
    top: 42%;
    left: 26.8%;
  }
  div.plus-light {
    background: url(${PlusLight});
    width: 24px;
    height: 24px;
    position: absolute;
    top: 85%;
    left: 71.11%;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 52px;
    text-align: center;
    color: #303030;
    margin-top: 48px;
  }
  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #808080;
    margin-top: 16px;
  }
  button {
    padding: 20px 57px;
    background: linear-gradient(0deg, #009eff, #009eff),
      linear-gradient(94.54deg, #009eff 0%, #29d1f5 100%);
    box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.5);
    border-radius: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 900;
    font-size: 24px;
    color: #ffffff;
    margin-top: 40px;
  }
  @media only screen and (max-width: 600px) {
    padding-top: 160px;
    padding-bottom: 160px;
    img {
      width: 80%;
    }
    h1 {
      font-size: 32px;
    }
    div.plus-dark {
      width: 8px;
      height: 8px;
      background-size: 100%;
      top: 20%;
      left: 10%;
    }
    div.plus-light {
      width: 16px;
      height: 16px;
      background-size: 100%;
      top: 55%;
      left: 70%;
    }
    p {
      font-size: 14px;
    }
    button {
      margin-top: 32px;
      padding: 12px 32px;
      font-size: 16px;
      margin-top: 32px;
    }
  }
`;

export default Footer;
