import React from "react";
import styled from "styled-components";
import Star from "../assets/images/star.svg";
import Revenue from "../assets/images/revenue.svg";
import { useSelector } from "react-redux";
import { month } from "../utils/monthName";

const MostTransaction = () => {
  const {
    transactionCategory,
    transactionMethod,
    highestRevenue,
    highestSelling,
  } = useSelector((state) => state.detailTransaction);
  return (
    <MostTransactionWrapper>
      <div className="top-section">
        <div className="item">
          <div className="image">
            <img src={Star} alt="" />
          </div>
          <div className="item-detail">
            <p className="item-title">Produk banyak terjual</p>
            <p className="item-name">{highestSelling?.name}</p>
            <p className="item-desc">
              Terjual sebanyak {highestSelling?.amount} kali
            </p>
          </div>
        </div>
        <div className="item">
          <div className="image image-revenue">
            <img src={Revenue} alt="" />
          </div>
          <div className="item-detail">
            <p className="item-title">Pemasukkan tertinggi</p>
            <p className="item-name">
              Rp{" "}
              {Object.keys(highestRevenue).length !== 0
                ? highestRevenue.amount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : "0"}
            </p>
            <p className="item-desc">
              Pada{" "}
              {Object.keys(highestRevenue).length !== 0
                ? parseInt(highestRevenue.date.split("-")[2]) +
                  " " +
                  month[parseInt(highestRevenue.date.split("-")[1]) - 1] +
                  " " +
                  highestRevenue.date.split("-")[0]
                : ""}
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <table>
          <thead>
            <tr>
              <td>Kategori</td>
              <td>Pemasukan</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="row-title">Billing</td>
              <td className="row-content">
                Rp{" "}
                {transactionCategory.billing
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
            <tr>
              <td className="row-title">Food</td>
              <td className="row-content">
                Rp{" "}
                {transactionCategory.food
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="divider">&nbsp;</div>
        <table>
          <thead>
            <tr>
              <td>Met. pembayaran</td>
              <td>Pemasukan</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="row-title">Cash</td>
              <td className="row-content">
                Rp{" "}
                {transactionMethod?.cash
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
            <tr>
              <td className="row-title">QRIS</td>
              <td className="row-content">
                Rp{" "}
                {transactionMethod?.qris
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="divider">&nbsp;</div>
        <table className="last">
          <tbody>
            <tr>
              <td className="row-title">Total pemasukan</td>
              <td className="row-content">
                Rp{" "}
                {(
                  parseInt(transactionCategory?.billing) +
                  parseInt(transactionCategory?.food)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
            <tr>
              <td className="row-title">Total refund</td>
              <td className="row-content refund">
                Rp{" "}
                {transactionCategory?.refund
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  .replace("-", "")}
              </td>
            </tr>
            <tr>
              <td className="row-title">Pendapatan</td>
              <td className="row-content revenue">
                Rp{" "}
                {(
                  parseInt(transactionCategory?.billing) +
                  parseInt(transactionCategory?.food) +
                  parseInt(transactionCategory?.refund)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </MostTransactionWrapper>
  );
};

const MostTransactionWrapper = styled.div`
  background: #25282e;
  border-radius: 16px;
  margin-top: 23px;
  padding: 40px;
  div.top-section {
    display: flex;
    div.item {
      display: flex;
      margin-right: 170px;
      div.image {
        background: rgba(250, 235, 158, 0.16);
        box-shadow: 0px 6px 8px rgba(250, 235, 158, 0.08);
        border-radius: 16px;
        width: 60px;
        height: 60px;
        padding-top: 14px;
        box-sizing: border-box;
        margin-right: 16px;
        img {
          margin: auto;
        }
      }
      div.image.image-revenue {
        background: rgba(158, 215, 250, 0.16);
        box-shadow: 0px 6px 8px rgba(158, 215, 250, 0.08);
        border-radius: 16px;
      }
      div.item-detail {
        p.item-title {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #5c6366;
        }
        p.item-name {
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #e5f8ff;
          margin-top: 8px;
        }
        p.item-desc {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #b0bec4;
          margin-top: 8px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    div.bottom-section {
      flex-direction: column !important;
    }
    div.bottom-section {
      td {
        padding-right: 0 !important;
        width: 50% !important;
      }
    }
  }
  div.bottom-section {
    display: flex;
    flex-direction: row;
    div.divider {
      border: 1.5px solid #303436;
      background: #303436;
      width: 0;
      margin: 0px 62px;
      margin-top: 40px;
    }
    table {
      margin-top: 40px;
      thead {
        tr td {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          color: #b0bec4;
        }
        td:first-child {
          padding-right: 120px;
        }
      }
      tbody {
        td.row-title {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #b0bec4;
          padding-top: 16px;
          padding-bottom: 8px;
        }
        td.row-content {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: #e6f8ff;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
    table.last {
      td:first-child {
        padding-right: 120px;
      }
      td:last-child {
        text-align: right;
      }
      td.refund {
        border-bottom: 1.5px solid #303436;
      }
      td.revenue {
        background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`;

export default MostTransaction;
