import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const updateFoodTitle = (data) => {
  data.data.map((dateData) => {
    return {
      date: dateData.date,
      data: dateData.data.map((detailData) => {
        let newName = detailData.name;
        try {
          if (newName == "Food")
            newName = detailData.split(" ")[1].replace("/[|]/g", "");
        } catch (e) {
          newName = detailData.name;
        }
        return { ...detailData };
      }),
    };
  });
  return {
    data: data.data,
    meta: data.meta,
    code: data.code,
  };
};
const date = new Date();

const initialState = {
  type: "month",
  month: date.getMonth() + 1,
  year: date.getFullYear(),
  startDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
  endDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
  transaction: [],
  transactionCategory: {
    billing: 0,
    food: 0,
    refund: 0,
  },
  transactionMethod: { cash: 0, qris: 0 },
  highestRevenue: {},
  highestSelling: {},
  pageActive: 1,
};

export const getDetailTransaction = createAsyncThunk(
  "detail-transaction/getDetailTransaction",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/transaction/detail`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: thunkAPI.getState().detailTransaction.type,
          month: thunkAPI.getState().detailTransaction.month,
          year: thunkAPI.getState().detailTransaction.year,
          start_date: thunkAPI.getState().detailTransaction.startDate,
          end_date: thunkAPI.getState().detailTransaction.endDate,
          outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
          page: thunkAPI.getState().detailTransaction.pageActive,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.log(err));
  }
);

export const getDetailTransactionCategory = createAsyncThunk(
  "detail-transaction/getDetailTransactionCategory",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/outlet/transaction-by-categories`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            type: thunkAPI.getState().detailTransaction.type,
            month: thunkAPI.getState().detailTransaction.month,
            year: thunkAPI.getState().detailTransaction.year,
            date: thunkAPI.getState().detailTransaction.startDate,
            outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
          },
        }
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => console.log(err));
  }
);

export const getDetailTransactionMethod = createAsyncThunk(
  "detail-transaction/getDetailTransactionMethod",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/earning-cash-qris`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: thunkAPI.getState().detailTransaction.type,
          month: thunkAPI.getState().detailTransaction.month,
          year: thunkAPI.getState().detailTransaction.year,
          date: thunkAPI.getState().detailTransaction.startDate,
          outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
        },
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => console.log(err));
  }
);

export const getHighestRevenue = createAsyncThunk(
  "detail-transaction/getHighestRevenue",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/outlet/transaction/detail/highest-revenue`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            type: thunkAPI.getState().detailTransaction.type,
            month: thunkAPI.getState().detailTransaction.month,
            year: thunkAPI.getState().detailTransaction.year,
            start_date: thunkAPI.getState().detailTransaction.startDate,
            end_date: thunkAPI.getState().detailTransaction.endDate,
            outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
          },
        }
      )
      .then((res) => {
        if (res.data.data.length !== 0) return res.data.data;
        else return {};
      })
      .catch((err) => console.log(err));
  }
);

export const getHighestSelling = createAsyncThunk(
  "detail-transaction/getHighestSelling",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/outlet/transaction/detail/highest-selling-product`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            type: thunkAPI.getState().detailTransaction.type,
            month: thunkAPI.getState().detailTransaction.month,
            year: thunkAPI.getState().detailTransaction.year,
            start_date: thunkAPI.getState().detailTransaction.startDate,
            end_date: thunkAPI.getState().detailTransaction.endDate,
            outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
          },
        }
      )
      .then(({ data }) => {
        return data.data;
      })
      .catch((err) => console.log(err));
  }
);

const detailTransactionSlice = createSlice({
  name: "detailTransaction",
  initialState,
  reducers: {
    changeType: (state, { payload }) => {
      state.type = payload;
    },
    nextPage: (state, { payload }) => {
      state.pageActive++;
    },
    prevPage: (state, { payload }) => {
      state.pageActive--;
    },
    changePage: (state, { payload }) => {
      state.pageActive = payload;
    },
    changeMonth: (state, { payload }) => {
      state.month = payload;
    },
    changeYear: (state, { payload }) => {
      state.year = payload;
    },
    changeStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    changeEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
  },
  extraReducers: {
    [getDetailTransaction.fulfilled]: (state, action) => {
      const data = updateFoodTitle(action.payload);
      state.transaction = data;
    },
    [getDetailTransactionCategory.fulfilled]: (state, action) => {
      if (action.payload !== null) {
        state.transactionCategory.billing = action.payload.billing;
        state.transactionCategory.food = action.payload.food;
        state.transactionCategory.refund = action.payload.refund;
      }
    },
    [getDetailTransactionMethod.fulfilled]: (state, action) => {
      state.transactionMethod = action.payload;
    },
    [getHighestRevenue.fulfilled]: (state, action) => {
      state.highestRevenue = action.payload;
    },
    [getHighestSelling.fulfilled]: (state, action) => {
      state.highestSelling = action.payload;
    },
  },
});

export const {
  nextPage,
  prevPage,
  changePage,
  changeMonth,
  changeYear,
  changeType,
  changeStartDate,
  changeEndDate,
} = detailTransactionSlice.actions;

export default detailTransactionSlice.reducer;
