import React, { useEffect } from "react";
import styled from "styled-components";

const Logout = ({ name, setShowLO }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowLO(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = `/login`;
  };

  return (
    <Wrapper>
      <div className="modal">
        <h1>Keluar akun</h1>
        <p>
          Apa kamu yakin ingin keluar akun <span id="name">{name}</span>?
        </p>
        <div className="button button-cancel" onClick={() => setShowLO(false)}>
          Batal
        </div>
        <div className="button button-logout" onClick={handleLogout}>
          Keluar
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: linear-gradient(167.96deg, #1c1d1f 0%, rgba(27, 33, 37, 0.8));
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  div.modal {
    background: #2d3139;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    width: 459px;
    height: 410px;
    margin: auto;
    margin-top: 227px;
    padding: 80px;
    h1 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 40px;
      color: #ffffff;
    }
    p {
      font-family: "Nunito Sans", sans-serif;
      font-size: 20px;
      color: #b0bec4;
      margin-top: 40px;
    }
    span#name {
      color: white;
      font-weight: 600;
    }
    div.button {
      display: inline-block;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      margin-top: 80px;
      cursor: pointer;
    }
    div.button-cancel {
      color: #b0bec4;
      margin-right: 158px;
    }
    div.button-logout {
      color: #ed213a !important;
    }
  }
`;

export default Logout;
