import React from "react";

const DetailTransactionOwnderData = ({
  time,
  name,
  category,
  shift,
  payment_method,
  amount,
}) => {
  return (
    <tr className="row">
      <td>{time}</td>
      <td>{name}</td>
      <td>{category}</td>
      <td>{shift}</td>
      <td>{payment_method}</td>
      {parseInt(amount) > 0 ? (
        <td className="transaction">
          Rp
          {" " + amount.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
        </td>
      ) : (
        <td className="transaction-refund">
          - Rp
          {" " + amount.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace("-", "")}
        </td>
      )}
    </tr>
  );
};

export default DetailTransactionOwnderData;
