import React from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { useSelector } from "react-redux";

const DoughnutChart = () => {
  const { analyticRevenueMethod } = useSelector((state) => state.analytic);
  return (
    <Wrapper>
      <Doughnut
        data={{
          labels: ["Cash", "Qris"],
          datasets: [
            {
              label: "My First Dataset",
              data: [analyticRevenueMethod.cash, analyticRevenueMethod.qris],
              backgroundColor: [
                "rgba(36, 255, 202, 1)",
                "rgba(152, 112, 252, 1)",
              ],
              hoverOffset: 4,
              borderWidth: "0",
            },
          ],
        }}
        width={136}
        height={136}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          segmentShowStroke: false,
          layout: {
            padding: 4,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
`;

export default DoughnutChart;
