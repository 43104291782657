import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NextButton from "../assets/images/arrow-next-dashboard.svg";
import PrevButton from "../assets/images/arrow-prev-dashboard.svg";
import DetailTransactionOwnerList from "./DetailTransactionOwnerList";
import { useDispatch, useSelector } from "react-redux";
import {
  nextPage,
  prevPage,
  getDetailTransaction,
  changePage,
} from "../features/detail-transaction/detailTransaction";
import MostTransaction from "./MostTransaction";

const month = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];
const DetailTransactionOwner = ({
  setPageDetail,
  highestSell,
  detTransRevDetail,
  detTransRev,
  detHighestRev,
}) => {
  const dispatch = useDispatch();
  const { transaction, pageActive } = useSelector(
    (state) => state.detailTransaction
  );

  const [page, setPage] = useState([]);
  const getPage = () => {
    let list = [];
    for (var i = 0; i < transaction?.meta?.pagination?.total_pages; i++) {
      list.push(i + 1);
    }
    setPage(list);
  };
  const getNextPage = () => {
    if (pageActive < transaction?.meta?.pagination?.total_pages) {
      dispatch(nextPage());
      dispatch(getDetailTransaction());
    }
  };
  const getPrevPage = () => {
    if (pageActive > 1) {
      dispatch(prevPage());
      dispatch(getDetailTransaction());
    }
  };
  useEffect(() => {
    getPage();
  }, [transaction]);

  return (
    <>
      <Wrapper>
        <table>
          <thead>
            <tr>
              <td>Tanggal/waktu</td>
              <td>Produk</td>
              <td>Kategori</td>
              <td>Shift</td>
              <td>Metode pembayaran</td>
              <td>Masuk/refund</td>
            </tr>
          </thead>
          <tbody>
            {transaction?.data?.map((x, index) => {
              return (
                <DetailTransactionOwnerList
                  month={month}
                  date={x.date}
                  data={x.data}
                  key={index}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2} className="show-data">
                Menampilkan {transaction?.meta?.pagination?.count} data dari{" "}
                {transaction?.meta?.pagination?.total} data
              </td>
              <td colSpan={4} className="pagination">
                <div className="right">
                  <div className="page-title">Halaman:</div>
                  <ul>
                    {page?.map((x, index) => {
                      if (
                        x >= transaction?.meta?.pagination?.current_page &&
                        x <= transaction?.meta?.pagination?.current_page + 4 &&
                        x !== transaction?.meta?.pagination?.total_pages
                      ) {
                        if (x === transaction?.meta?.pagination?.current_page)
                          return (
                            <li
                              className="active"
                              onClick={() => {
                                dispatch(changePage(x));
                                dispatch(getDetailTransaction());
                              }}
                              key={index}
                            >
                              {x}
                            </li>
                          );
                        else if (
                          x ==
                          transaction?.meta?.pagination?.current_page + 4
                        )
                          return (
                            <li className="dot" key={index}>
                              ...
                            </li>
                          );
                        else
                          return (
                            <li
                              onClick={() => {
                                dispatch(changePage(x));
                                dispatch(getDetailTransaction());
                              }}
                              key={index}
                            >
                              {x}
                            </li>
                          );
                      } else if (
                        x >= transaction?.meta?.pagination?.total_pages
                      )
                        return (
                          <li
                            onClick={() => {
                              dispatch(changePage(x));
                              dispatch(getDetailTransaction());
                            }}
                            key={index}
                          >
                            {x}
                          </li>
                        );
                    })}
                  </ul>
                  <img
                    src={PrevButton}
                    alt=""
                    id="previous"
                    onClick={getPrevPage}
                  />
                  <img
                    src={NextButton}
                    alt=""
                    id="next"
                    onClick={getNextPage}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </Wrapper>
      <MostTransaction />
    </>
  );
};

const Wrapper = styled.div`
  background: #25282e;
  border-radius: 16px;
  margin-top: 23px;
  padding: 24px 32px;
  table {
    width: 100%;
    thead {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #5c6366;
      border-bottom: 1.5px solid #303436;
      td {
        padding-bottom: 16px;
      }
      td:nth-child(1) {
        width: 15% !important;
      }
      td:nth-child(2) {
        width: 30% !important;
      }
      td:nth-child(3) {
        width: 15% !important;
      }
      td:nth-child(4) {
        width: 15% !important;
      }
      td:nth-child(5) {
        width: 10% !important;
      }
      td:nth-child(6) {
        width: 15% !important;
      }
    }
    tbody {
      border-bottom: 1.5px solid #303436;
      tr.title {
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #e5f8ff;
        td {
          padding-top: 16px;
          padding-bottom: 8px;
        }
      }
      tr.row {
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #b0bec4;
        td {
          padding: 8px 0;
        }
        td.transaction {
          font-weight: 600;
          background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        td.transaction-refund {
          color: #ed213a;
        }
      }
      tr.row:last-child {
        td {
          padding-bottom: 24px;
        }
      }
    }
    tfoot {
      td {
        padding-top: 24px;
        padding-bottom: 8px;
      }
      td.show-data {
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5c6366;
      }
      td.pagination {
        position: relative;
        div.right {
          position: absolute;
          right: 20px;
        }
        img {
          display: inline-block;
          cursor: pointer;
        }
        img#next {
          margin-left: 47px;
        }
        div.page-title {
          display: inline-block;
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #5c6366;
        }
        ul {
          display: inline-block;
          margin-right: 82px;
          li {
            display: inline-block;
            margin: 0 24px;
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #b0bec4;
            cursor: pointer;
          }
          li.active {
            font-weight: 700;
            color: #009eff;
          }
          li.dot {
            background: #111518;
            border-radius: 4px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`;

// const MostTransaction = ({
//   highestSell,
//   detTransRevDetail,
//   detTransRev,
//   detHighestRev,
// }) => {
//   return (
//     <MostTransactionWrapper>
//       <div className="top-section">
//         <div className="item">
//           <div className="image">
//             <img src={Star} alt="" />
//           </div>
//           <div className="item-detail">
//             <p className="item-title">Produk banyak terjual</p>
//             <p className="item-name">{highestSell.name}</p>
//             <p className="item-desc">
//               Terjual sebanyak {highestSell.amount} kali
//             </p>
//           </div>
//         </div>
//         {/* <div className="item">
//           <div className="image image-revenue">
//             <img src={Revenue} alt="" />
//           </div>
//           <div className="item-detail">
//             <p className="item-title">Pemasukkan tertinggi</p>
//             <p className="item-name">
//               Rp{" "}
//               {Object.keys(detHighestRev).length !== 0
//                 ? detHighestRev.amount
//                     .toString()
//                     .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
//                 : "0"}
//             </p>
//             <p className="item-desc">
//               Pada{" "}
//               {Object.keys(detHighestRev).length !== 0
//                 ? parseInt(detHighestRev.date.split("-")[2]) +
//                   " " +
//                   month[parseInt(detHighestRev.date.split("-")[1]) - 1] +
//                   " " +
//                   detHighestRev.date.split("-")[0]
//                 : ""}
//             </p>
//           </div>
//         </div> */}
//       </div>
//       <div className="bottom-section">
//         {/* <table>
//           <thead>
//             <tr>
//               <td>Kategori</td>
//               <td>Pemasukan</td>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td className="row-title">Billing</td>
//               <td className="row-content">
//                 Rp{" "}
//                 {detTransRevDetail.billing
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
//               </td>
//             </tr>
//             <tr>
//               <td className="row-title">Food</td>
//               <td className="row-content">
//                 Rp{" "}
//                 {detTransRevDetail.food
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
//               </td>
//             </tr>
//           </tbody>
//         </table> */}
//         <div className="divider">&nbsp;</div>
//         {/* <table>
//           <thead>
//             <tr>
//               <td>Met. pembayaran</td>
//               <td>Pemasukan</td>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td className="row-title">Cash</td>
//               <td className="row-content">
//                 Rp{" "}
//                 {detTransRev.cash
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
//               </td>
//             </tr>
//             <tr>
//               <td className="row-title">QRIS</td>
//               <td className="row-content">
//                 Rp{" "}
//                 {detTransRev.qris
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
//               </td>
//             </tr>
//           </tbody>
//         </table> */}
//         <div className="divider">&nbsp;</div>
//         {/* <table className="last">
//           <tbody>
//             <tr>
//               <td className="row-title">Total pemasukan</td>
//               <td className="row-content">
//                 Rp{" "}
//                 {(
//                   parseInt(detTransRevDetail.billing) +
//                   parseInt(detTransRevDetail.food)
//                 )
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
//               </td>
//             </tr>
//             <tr>
//               <td className="row-title">Total refund</td>
//               <td className="row-content refund">
//                 Rp{" "}
//                 {detTransRevDetail.refund
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
//                   .replace("-", "")}
//               </td>
//             </tr>
//             <tr>
//               <td className="row-title">Pendapatan</td>
//               <td className="row-content revenue">
//                 Rp{" "}
//                 {(
//                   parseInt(detTransRevDetail.billing) +
//                   parseInt(detTransRevDetail.food) +
//                   parseInt(detTransRevDetail.refund)
//                 )
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
//               </td>
//             </tr>
//           </tbody>
//         </table> */}
//       </div>
//     </MostTransactionWrapper>
//   );
// };

// const MostTransactionWrapper = styled.div`
//   background: #25282e;
//   border-radius: 16px;
//   margin-top: 23px;
//   padding: 40px;
//   div.top-section {
//     display: flex;
//     div.item {
//       display: flex;
//       margin-right: 170px;
//       div.image {
//         background: rgba(250, 235, 158, 0.16);
//         box-shadow: 0px 6px 8px rgba(250, 235, 158, 0.08);
//         border-radius: 16px;
//         width: 60px;
//         height: 60px;
//         padding-top: 14px;
//         box-sizing: border-box;
//         margin-right: 16px;
//         img {
//           margin: auto;
//         }
//       }
//       div.image.image-revenue {
//         background: rgba(158, 215, 250, 0.16);
//         box-shadow: 0px 6px 8px rgba(158, 215, 250, 0.08);
//         border-radius: 16px;
//       }
//       div.item-detail {
//         p.item-title {
//           font-family: "Nunito Sans", sans-serif;
//           font-style: normal;
//           font-weight: 600;
//           font-size: 12px;
//           line-height: 16px;
//           color: #5c6366;
//         }
//         p.item-name {
//           font-family: "Montserrat", sans-serif;
//           font-style: normal;
//           font-weight: 700;
//           font-size: 16px;
//           line-height: 20px;
//           color: #e5f8ff;
//           margin-top: 8px;
//         }
//         p.item-desc {
//           font-family: "Nunito Sans", sans-serif;
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           line-height: 16px;
//           color: #b0bec4;
//           margin-top: 8px;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     div.bottom-section {
//       flex-direction: column !important;
//     }
//     div.bottom-section {
//       td {
//         padding-right: 0 !important;
//         width: 50% !important;
//       }
//     }
//   }
//   div.bottom-section {
//     display: flex;
//     flex-direction: row;
//     div.divider {
//       border: 1.5px solid #303436;
//       background: #303436;
//       width: 0;
//       margin: 0px 62px;
//       margin-top: 40px;
//     }
//     table {
//       margin-top: 40px;
//       thead {
//         tr td {
//           font-family: "Nunito Sans", sans-serif;
//           font-style: normal;
//           font-weight: 400;
//           font-size: 20px;
//           line-height: 27px;
//           color: #b0bec4;
//         }
//         td:first-child {
//           padding-right: 120px;
//         }
//       }
//       tbody {
//         td.row-title {
//           font-family: "Nunito Sans", sans-serif;
//           font-style: normal;
//           font-weight: 400;
//           font-size: 16px;
//           line-height: 22px;
//           color: #b0bec4;
//           padding-top: 16px;
//           padding-bottom: 8px;
//         }
//         td.row-content {
//           font-family: "Nunito Sans", sans-serif;
//           font-style: normal;
//           font-weight: 700;
//           font-size: 16px;
//           line-height: 22px;
//           color: #e6f8ff;
//           padding-top: 8px;
//           padding-bottom: 8px;
//         }
//       }
//     }
//     table.last {
//       td:first-child {
//         padding-right: 120px;
//       }
//       td:last-child {
//         text-align: right;
//       }
//       td.refund {
//         border-bottom: 1.5px solid #303436;
//       }
//       td.revenue {
//         background: linear-gradient(88.44deg, #009eff 0%, #29d1f5 100%);
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         background-clip: text;
//         text-fill-color: transparent;
//         font-style: normal;
//         font-weight: 700;
//         font-size: 20px;
//         line-height: 24px;
//       }
//     }
//   }
// `;

export default DetailTransactionOwner;
