import React from "react";
import styled from "styled-components";
import ArrNext from "../assets/images/arrow-next.svg";
import DataSent from "../assets/images/data-sent.svg";
import axios from "axios";
import useForm from "../custom-hooks/useForm";
import validate from "../utils/validateInfo";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { addCookies, checkCookies } from "../utils/cookies";
import ArrPrev from "../assets/images/arrow-prev-dark.svg";
import { ContactUsModal } from ".";

const ContactUsForm = ({
  submitForm,
  openModal,
  isSuccess,
  values,
  setValues,
}) => {
  const { handleChange, handleSubmit, errors } = useForm(
    submitForm,
    openModal,
    validate,
    values,
    setValues
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const handleNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      handleChange(e);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const handleBack = () => {
    window.history.go(-1);
  };
  const handleDisabled = () => {
    if (values.name && values.email && values.phone && values.address) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  return (
    <Wrapper>
      <div
        className={checkCookies("SBS_CUS") || isSuccess ? "hidden" : "back-btn"}
        onClick={handleBack}
      >
        <img src={ArrPrev} alt="" className="inline-block" />
        <div className="inline-block">Kembali</div>
      </div>
      <div
        className={
          checkCookies("SBS_CUS") || isSuccess ? "main hidden" : "main"
        }
      >
        <h1>Kontak kami</h1>
        <p className="mx-auto">
          Pada halaman ini, kamu akan mengisi form yang telah disediakan. Tim
          kami akan menghubungi kamu lewat kontak yang kamu berikan untuk
          melakukan pendekatan. Kamu bisa menghubungi kami dalam 1 x 24 jam.
        </p>
        <div>
          <form action="" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="" className="block">
                Nama kamu
              </label>
              <input
                type="text"
                name="name"
                className="appearance-none rounded px-3 py-2 leading-tight"
                placeholder="Masukkan nama kamu"
                value={values.name}
                onChange={handleChange}
                onKeyUp={handleDisabled}
              />
              {errors.name && (
                <span className="text-red-600">{errors.name}</span>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="" className="block">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="appearance-none rounded px-3 py-2 leading-tight"
                placeholder="Masukkan email kamu"
                value={values.email}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={handleChange}
                onKeyUp={handleDisabled}
              />
              {errors.email && (
                <span className="text-red-600">{errors.email}</span>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="" className="block">
                What'sApp / Nomor handphone aktif
              </label>
              <div className="input-wrapper">
                <div className="prefix mr-2">+62</div>
                <input
                  type="tel"
                  name="phone"
                  className="appearance-none rounded  px-3 py-2 leading-tight"
                  value={values.phone}
                  onChange={(e) => handleNumber(e)}
                  onKeyUp={handleDisabled}
                />
              </div>
              {errors.phone && (
                <span className="text-red-600">{errors.phone}</span>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="" className="block">
                Alamat warnet
              </label>
              <textarea
                type="text"
                name="address"
                className="appearance-none rounded  px-3 py-2 leading-tight"
                rows="4"
                placeholder="Masukkan alamat warnet kamu"
                value={values.address}
                onChange={handleChange}
                onKeyUp={handleDisabled}
              ></textarea>
              {errors.address && (
                <span className="text-red-600">{errors.address}</span>
              )}
            </div>
            <div className="mb-4 text-right">
              <button disabled={isDisabled}>
                Lanjut <img src={ArrNext} alt="" className="inline-block" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  margin-top: 0;
  div.back-btn {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #009eff;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 40px;
  }
  div.back-btn img {
    width: 12px;
    margin-right: 8px;
    transition: transform 0.5s;
  }
  div.back-btn:hover img {
    transform: translateX(-5px);
  }
  div.main h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
  }
  div.main p {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    width: 59.72%;
    text-align: justify;
    margin-top: 24px;
  }
  form {
    background: #f5fdff;
    box-shadow: 4px 4px 8px 4px rgba(48, 48, 48, 0.04),
      inset 4px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    padding: 48px;
    width: 496px;
    margin: 32px auto;
  }
  form label {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
  }
  form label::after {
    content: "*";
    color: red;
  }
  form > div:not(:first-child) {
    margin-top: 40px;
  }
  form input {
    /* margin-bottom: 20px; */
    width: 100%;
    border: 1.5px solid #80cdff;
  }
  form input:focus {
    border: 2px solid #009eff;
    outline: none;
  }
  form textarea {
    margin-bottom: 20px;
    width: 100%;
    border: 1.5px solid #80cdff;
    resize: none;
  }
  form textarea:focus {
    border: 2px solid #009eff;
    outline: none;
  }
  form button {
    background: #009eff;
    box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.5);
    border-radius: 6px;
    color: white;
    padding: 12px 12px 12px 32px;
  }
  form button:disabled {
    background: grey;
    box-shadow: none;
  }
  form button img {
    margin-left: 10px;
    width: 14px;
    transition: transform 0.5s;
  }
  form button:hover img {
    transform: translateX(5px);
  }
  form button:disabled:hover img {
    transform: none;
  }
  form button form span {
    color: red;
  }
  div.input-wrapper {
    display: flex;
    border: 1px solid black;
    margin-bottom: 20px;
    background: white;
    padding: 5px 10px;
    border: 1.5px solid #80cdff;
    border-radius: 4px;
  }
  div.input-wrapper input {
    margin: 0;
    padding: 0;
    border: 0;
  }
  div.input-wrapper:focus-within {
    border: 2px solid #009eff;
    outline: none;
  }

  @media only screen and (max-width: 600px) {
    div.main {
      margin-top: 62px;
    }
    div.main h1 {
      font-size: 24px;
      text-align: left;
      margin: 0 30px;
    }
    div.main p {
      font-size: 14px;
      width: 80%;
      margin: 16px 30px 30px 30px !important;
    }
    div.back-btn {
      top: -40px;
      left: 30px;
    }
    div.back-btn img {
      width: 7px;
    }
    div.back-btn div {
      font-size: 12px;
    }
    form {
      width: 90%;
    }

    form > div:not(:first-child) {
      margin-top: 29px;
    }
  }
`;

export default ContactUsForm;
