import React from "react";
import { useState } from "react";
import styled from "styled-components";
import App1 from "../assets/images/app1.svg";
import App2 from "../assets/images/app2.svg";
import App3 from "../assets/images/app3.svg";
import App4 from "../assets/images/app4.svg";
import ArrNext from "../assets/images/arrow-next.svg";
import ArrNextMob from "../assets/images/arrow-next-mobile.svg";
import ArrPrev from "../assets/images/arrow-prev.svg";
import ArrPrevMob from "../assets/images/arrow-prev-mobile.svg";
import Line from "../assets/images/line.svg";
import PlusLight from "../assets/images/plus-light.svg";
import PlusDark from "../assets/images/plus-dark.svg";
import Slider from "react-slick";
import AboutUsContent from "./AboutUsContent";
import Spark1 from "../assets/images/about-us-bg-right.svg";
import Spark2 from "../assets/images/about-us-bg-left.svg";

const AboutUs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };

  return (
    <Wrapper data-aos="fade-in" id="about_us" data-aos-once="true">
      <div className="line-top">&nbsp;</div>
      <div className="line-bottom">&nbsp;</div>
      <div className="plus-dark">&nbsp;</div>
      <div className="plus-light">&nbsp;</div>
      <div className="spark-right">&nbsp;</div>
      <div className="spark-left">&nbsp;</div>
      <h1 data-aos="fade-right" data-aos-once="true">
        Apa itu SBS
      </h1>

      <div
        className="main"
        data-aos="fade-right"
        data-aos-delay="200"
        data-aos-once="true"
      >
        <p>
          SBS (Stream Billing System) adalah software yang membantu kamu dalam
          mengelola warnet. Mulai dari pengelolaan keuangan, PC, paket warnet,
          member warnet hingga pengelolaan karyawan.
        </p>
        <p>
          Tersedia di platform PC dan smartphone untuk kamu sebagai Pemilik
          warnet untuk mengecek segala urusan di warnet kamu.
        </p>
      </div>

      <Slider {...settings}>
        <AboutUsContent
          image={App1}
          content={
            <>
              <b>Kelola</b> PC Member dengan <b>mudah</b>
            </>
          }
        />
        <AboutUsContent
          image={App2}
          content={
            <>
              <b>Atur akun</b> PC Member dengan berbagai opsi
            </>
          }
        />
        <AboutUsContent
          image={App3}
          content={
            <>
              Segala <b>transaksi</b> tercatat dengan <b>lengkap</b>
            </>
          }
        />
        <AboutUsContent
          image={App4}
          content={
            <>
              <b>Menampilkan</b> semua <b>aktivitas</b> yang berlangsung
            </>
          }
        />
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top: 80px;
  padding-bottom: 232px;
  position: relative;
  div.main {
    margin: 0 13.33%;
  }
  div.line-top,
  div.line-bottom {
    width: 200px;
    height: 1px;
    position: absolute;
  }
  div.line-top {
    background: url(${Line});
    top: 1.5px;
    left: 12.08%;
  }
  div.line-bottom {
    background: url(${Line});
    bottom: 0;
    left: 12.08%;
  }
  div.plus-dark {
    background: url(${PlusDark});
    width: 16px;
    height: 16px;
    position: absolute;
    top: 19.77%;
    left: 85.07%;
  }
  div.plus-light {
    background: url(${PlusLight});
    width: 24px;
    height: 24px;
    position: absolute;
    top: 6.208%;
    left: 58.82%;
  }
  div.spark-right {
    background: url(${Spark1}) no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    width: 27.77%;
    height: 500px;
  }
  div.spark-left {
    background: url(${Spark2}) no-repeat;
    position: absolute;
    bottom: 282px;
    left: 0;
    width: 27.77%;
    height: 500px;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 52px;
    font-weight: 900;
    color: #303030;
    margin-left: 13.33%;
  }
  div.main p {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* width: 643px; */
    margin-top: 24px;
  }

  .slick-prev,
  .slick-next {
    background: #009eff;
    border-radius: 8px;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 93%;
    z-index: 8 !important;
  }
  .slick-prev {
    left: 78.8%;
  }
  .slick-prev::before {
    content: url(${ArrPrev}) !important;
    opacity: 1 !important;
    transform: scale(0.2);
  }
  .slick-next {
    left: 85.5%;
  }
  .slick-next::before {
    content: url(${ArrNext}) !important;
    opacity: 1 !important;
  }

  div.about-content img {
    width: 84%;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 40px;
    padding-bottom: 94px;
    div.line-top,
    div.line-bottom {
      left: 8%;
      width: 100px;
    }
    div.spark-right,
    div.spark-left {
      display: none;
    }
    div.plus-light,
    div.plus-dark {
      background-size: 100%;
    }
    div.plus-light {
      width: 16px;
      height: 16px;
      top: 2.5%;
      left: 80.8%;
    }
    div.plus-dark {
      width: 8px;
      height: 8px;
      top: 25.95%;
      left: 85.8%;
    }
    h1 {
      margin: 0 8%;
      font-size: 32px;
    }
    div.main {
      margin: 0 8%;
    }
    div.main p {
      font-size: 14px;
      width: 100%;
    }
    div.secondary {
      margin-top: 40px;
    }
    div.secondary p {
      font-size: 20px;
      margin: 0 8%;
      width: 204px;
    }
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 32px;
      top: 79.32%;
      padding-bottom: 1%;
    }
    .slick-prev {
      left: 70%;
    }
    .slick-next {
      left: 80%;
    }
    .slick-next::before {
      content: url(${ArrNextMob}) !important;
    }
    .slick-prev::before {
      content: url(${ArrPrevMob}) !important;
    }
  }
`;

export default AboutUs;
