import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../../assets/images/logo-sbs.svg";
import NavbarIcon from "../../assets/images/navbar-icon.svg";
import CloseIcon from "../../assets/images/close-nav.svg";
import "./Navbar.css";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const responsive = () => {
    const x = document.getElementById("mobile");
    if (!x.className.includes("hidden")) {
      x.classList.add("hidden");
    } else {
      x.classList.remove("hidden");
    }
  };

  const scrollTo = (name) => {
    var element = document.getElementById(name);
    element.scrollIntoView({ behavior: "smooth" });
    setShowNav(false);
  };

  return (
    <>
      <Wrapper id="nav" className="mb-2">
        <a href="/" className="logo ml-5">
          <img src={Logo} alt="" />
        </a>
        <a
          href="/"
          className="link home"
          data-aos="fade-down"
          data-aos-duration="100"
          data-aos-delay="900"
          data-aos-once="true"
        >
          Beranda
        </a>
        <Link
          to="/#about_us"
          className="link"
          onClick={() => scrollTo("about_us")}
          data-aos="fade-down"
          data-aos-duration="100"
          data-aos-delay="800"
          data-aos-once="true"
        >
          Produk
        </Link>
        <Link
          to="/#offer"
          className="link"
          onClick={() => scrollTo("offer")}
          data-aos="fade-down"
          data-aos-duration="100"
          data-aos-delay="700"
          data-aos-once="true"
        >
          Fitur
        </Link>
        {/* <Link
          to="/#social_proof"
          className="link"
          onClick={() => scrollTo("social_proof")}
          data-aos="fade-down"
          data-aos-duration="100"
          data-aos-delay="600"
          data-aos-once="true"
        >
          Review
        </Link> */}
        <Link
          to="/capacity"
          className="link"
          onClick={() => setShowNav(false)}
          data-aos="fade-down"
          data-aos-duration="100"
          data-aos-delay="500"
          data-aos-once="true"
        >
          Capacity
        </Link>
        <a
          href=""
          className={showNav ? "hidden" : "icon"}
          onClick={(e) => {
            e.preventDefault();
            setShowNav(!showNav);
          }}
        >
          <img src={NavbarIcon} alt="" />
        </a>
        <a
          href=""
          className={!showNav ? "hidden" : "icon mt-2"}
          onClick={(e) => {
            e.preventDefault();
            setShowNav(!showNav);
          }}
        >
          <img src={CloseIcon} alt="" />
        </a>
      </Wrapper>
      <Mobile id="mobile" className={showNav ? "active" : "closed-nav"}>
        <a href="/" className="link home">
          Beranda
        </a>
        <Link
          to="/#about_us"
          className="link"
          onClick={() => scrollTo("about_us")}
        >
          Produk
        </Link>
        <Link to="/#offer" className="link" onClick={() => scrollTo("offer")}>
          Fitur
        </Link>
        {/* <Link
          to="/#social_proof"
          className="link"
          onClick={() => scrollTo("social_proof")}
        >
          Review
        </Link> */}
        <Link to="/capacity" className="link" onClick={() => setShowNav(false)}>
          Capacity
        </Link>
      </Mobile>
    </>
  );
};

const Mobile = styled.div`
  position: fixed;
  top: 50px;
  z-index: 9;
  bottom: 0;
  right: -100%;
  width: 100%;
  background: white;
  padding-top: 40px;
  .link {
    display: block;
    text-align: center;
    font-family: "Nuniton Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #009eff;
    margin-top: 40px;
  }
`;

const Wrapper = styled.nav`
  position: sticky;
  background: white;
  z-index: 10;
  top: 0;
  .link {
    font-family: "Nuniton Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }
  a.home {
    margin-left: 11.11%;
  }
  @media only screen and (max-width: 770px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    .link {
      color: #009eff;
      text-align: center;
    }
    a.home {
      margin-left: 0;
    }
  }
`;

export default Navbar;
