import React, { useEffect, useState } from "react";
import { NavOwner, SummaryOwner, AnalyticOwner } from "../components";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ChangePassword from "../components/ChangePassword";
import Logout from "../components/Logout";
import { getOutlet } from "../features/outlet/outletSlice";
import { getSummary, getSummaryDetail } from "../features/summary/summarySlice";
import {
  getAnalytic,
  getAnalyticDetail,
  getAnalyticHighest,
  getAnalyticMethod,
} from "../features/analytic/analyticSlice";
import {
  getDetailTransaction,
  getDetailTransactionCategory,
  getDetailTransactionMethod,
  getHighestRevenue,
  getHighestSelling,
} from "../features/detail-transaction/detailTransaction";

const OwnerDashboard = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const { outlet, selected_outlet_name } = useSelector((state) => state.outlet);

  const getUserInfo = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setName(res.data.name);
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/login";
      });
  };

  useEffect(() => {
    getUserInfo();
    dispatch(getOutlet());
  }, []);

  useEffect(() => {
    if (outlet.data) {
      if (outlet.data.length > 0) {
        dispatch(getSummary());
        dispatch(getSummaryDetail());
        dispatch(getAnalytic());
        dispatch(getAnalyticDetail());
        dispatch(getAnalyticMethod());
        dispatch(getAnalyticHighest());
        dispatch(getDetailTransaction());
        dispatch(getDetailTransactionCategory());
        dispatch(getDetailTransactionMethod());
        dispatch(getHighestRevenue());
        dispatch(getHighestSelling());
      }
    }
  }, [outlet, selected_outlet_name]);

  const [showCP, setShowCP] = useState(false);
  const [showLO, setShowLO] = useState(false);
  return (
    <Wrapper>
      {showLO ? <Logout name={name} setShowLO={setShowLO} /> : ""}
      {showCP ? <ChangePassword setShowCP={setShowCP} /> : ""}
      <NavOwner
        name={name}
        showCP={showCP}
        setShowCP={setShowCP}
        setShowLO={setShowLO}
      />
      <SummaryOwner />
      <AnalyticOwner />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background: #1c1d1f;
  height: 100%;
`;

export default OwnerDashboard;
