import React from "react";
import DetailTransactionOwnderData from "./DetailTransactionOwnderData";
import DetailTransactionOwnerDate from "./DetailTransactionOwnerDate";

const DetailTransactionOwnerList = ({ month, date, data }) => {
  return (
    <>
      <DetailTransactionOwnerDate month={month} date={date} />
      {data.map((data, index) => (
        <DetailTransactionOwnderData {...data} key={"data-" + index} />
      ))}
    </>
  );
};

export default DetailTransactionOwnerList;
