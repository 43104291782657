import React, { useState } from "react";
import Select from "react-select";
import { useEffect } from "react/cjs/react.development";
import { useGlobalContext } from "../../context/context";
import "./Search.css";

const Search = () => {
  const { outlets, setSelectedOutletId } = useGlobalContext();
  const transformed = outlets.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <>
      <p className="font-semibold mb-3 title">Kapasitas</p>
      <Select
        options={transformed}
        placeholder="Cari warnet..."
        className="appearance-none"
        onChange={(e) => {
          setSelectedOutletId(e.value);
        }}
      />
      {/* <input
        type="text"
        className="w-full appearance-none border rounded-sm px-3 py-2 leading-tight focus:outline-none focus:shadow-outline"
        style={{ borderColor: "#93A4A7" }}
        placeholder="Cari warnet..."
      /> */}
    </>
  );
};

export default Search;
