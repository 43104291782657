import { useState, useEffect } from "react";

const useForm = (submitForm, openModal, validate, values, setValues) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setValues({ ...values, [name]: value.replace(/\s/g, "") });
    } else setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const clearForm = () => {
    setValues({ name: "", email: "", phone: "", address: "" });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      openModal();
    }
  }, [errors]);

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    clearForm,
  };
};

export default useForm;
