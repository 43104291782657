import React from "react";
import styled from "styled-components";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

const PieChart = () => {
  const { analyticRevenueDetail } = useSelector((state) => state.analytic);
  return (
    <Wrapper>
      <Pie
        data={{
          labels: ["Billing", "Food", "Refund"],
          datasets: [
            {
              label: "My First Dataset",
              data: [
                analyticRevenueDetail.billing,
                analyticRevenueDetail.food,
                analyticRevenueDetail.refund
                  ? analyticRevenueDetail.refund.replace("-", "")
                  : 0,
              ],
              backgroundColor: [
                "#29D1F5",
                "rgba(240, 101, 0, 1)",
                "rgba(237, 33, 58, 1)",
              ],
              hoverOffset: 4,
              borderWidth: "0",
            },
          ],
        }}
        width={208}
        height={208}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          layout: {
            padding: 4,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
`;

export default PieChart;
