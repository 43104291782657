import React, { useState } from "react";
import styled from "styled-components";
import LoginImage from "../assets/images/login-image.png";
import { ForgotPasswordForm, ForgotDone } from "../components";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);

  return (
    <Wrapper>
      {!success ? (
        <ForgotPasswordForm setSuccess={setSuccess} />
      ) : (
        <ForgotDone setSuccess={setSuccess} />
      )}
      <div className="image">
        <img src={LoginImage} alt="" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: linear-gradient(167.96deg, #1c1d1f 0%, #1b2125 100%);
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div.image {
    margin-top: 252px;
    margin-left: 160px;
  }
  @media screen and (max-width: 1200px) {
    div.image {
      display: none;
    }
  }
`;

export default ForgotPassword;
