import React, { useState } from "react";
import styled from "styled-components";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./SummaryOwner.css";
import SummaryDetail from "./SummaryDetail";
import FilterSummary from "../FilterSummary";

const SummaryOwner = () => {
  const [selectedRange, setSelectedRange] = useState(1);
  return (
    <Wrapper>
      <FilterSummary
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />
      <SummaryDetail />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 40px 72px;
`;

export default SummaryOwner;
