import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const date = new Date();

const initialState = {
  type: "monthly",
  typeDetail: "week",
  startDate: `${date.getFullYear()}-1-1`,
  endDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
  day: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
  month: date.getMonth() + 1,
  year: date.getFullYear(),
  analyticRevenue: {},
  analyticRevenueDetail: { billing: 0, food: 0, refund: 0 },
  analyticRevenueMethod: { cash: 0, qris: 0 },
  analyticRevenueHighest: {
    highest_billing: { amount: 0, date: "" },
    highest_food: { amount: 0, date: "" },
    highest_transaction: { amount: 0, date: "" },
    lowest_transaction: { amount: 0, date: "" },
  },
};

export const getAnalytic = createAsyncThunk(
  "analytic/getAnalytic",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/transaction/analytic`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: thunkAPI.getState().analytic.type,
          month: thunkAPI.getState().analytic.month,
          year: thunkAPI.getState().analytic.year,
          outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
        },
      })
      .then(({ data }) => {
        if (data.data == null) {
          return "";
        } else {
          return data.data;
        }
      })
      .catch((err) => console.log(err));
  }
);

export const getAnalyticDetail = createAsyncThunk(
  "analytic/getAnalyticDetail",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/outlet/transaction-by-categories`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            type: thunkAPI.getState().analytic.typeDetail,
            month: thunkAPI.getState().analytic.month,
            year: thunkAPI.getState().analytic.year,
            start_date: thunkAPI.getState().analytic.startDate,
            end_date: thunkAPI.getState().analytic.endDate,
            outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
          },
        }
      )
      .then((res) => {
        if (res.data.data == null)
          return {
            billing: 0,
            food: 0,
            refund: 0,
          };
        else {
          return {
            billing: res.data.data.billing,
            food: res.data.data.food,
            refund: res.data.data.refund,
          };
        }
      })
      .catch((err) => console.log(err));
  }
);

export const getAnalyticMethod = createAsyncThunk(
  "analytic/getAnalyticMethod",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/earning-cash-qris`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: thunkAPI.getState().analytic.typeDetail,
          month: thunkAPI.getState().analytic.month,
          year: thunkAPI.getState().analytic.year,
          start_date: thunkAPI.getState().analytic.startDate,
          end_date: thunkAPI.getState().analytic.endDate,
          outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
        },
      })
      .then((res) => {
        if (res.data.data == null) return { cash: 0, qris: 0 };
        else
          return {
            cash: res.data.data.cash,
            qris: res.data.data.qris,
          };
      })
      .catch((err) => console.log(err));
  }
);

export const getAnalyticHighest = createAsyncThunk(
  "analytic/getAnalyticHighest",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/transaction/specific`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: thunkAPI.getState().analytic.type,
          month: thunkAPI.getState().analytic.month,
          year: thunkAPI.getState().analytic.year,
          outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
        },
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => console.log(err));
  }
);

const analyticSlice = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    changeType: (state, { payload }) => {
      state.type = payload;
    },
    changeTypeDetail: (state, { payload }) => {
      state.typeDetail = payload;
    },
    changeStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    changeEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
    changeDay: (state, { payload }) => {
      state.day = payload;
    },
    changeMonth: (state, { payload }) => {
      state.month = payload;
    },
    changeYear: (state, { payload }) => {
      state.year = payload;
    },
  },
  extraReducers: {
    [getAnalytic.fulfilled]: (state, action) => {
      state.analyticRevenue = action.payload;
    },
    [getAnalyticDetail.fulfilled]: (state, action) => {
      state.analyticRevenueDetail = action.payload;
    },
    [getAnalyticMethod.fulfilled]: (state, action) => {
      state.analyticRevenueMethod = action.payload;
    },
    [getAnalyticHighest.fulfilled]: (state, action) => {
      state.analyticRevenueHighest.highest_billing.amount = action.payload
        .highest_billing
        ? action.payload.highest_billing.amount
        : 0;
      state.analyticRevenueHighest.highest_billing.date = action.payload
        .highest_billing
        ? action.payload.highest_billing.date
        : "";
      state.analyticRevenueHighest.highest_food.amount = action.payload
        .highest_food
        ? action.payload.highest_food.amount
        : 0;
      state.analyticRevenueHighest.highest_food.date = action.payload
        .highest_food
        ? action.payload.highest_food.date
        : "";
      state.analyticRevenueHighest.highest_transaction.amount = action.payload
        .highest_transaction
        ? action.payload.highest_transaction.amount
        : 0;
      state.analyticRevenueHighest.highest_transaction.date = action.payload
        .highest_transaction
        ? action.payload.highest_transaction.date
        : "";
      state.analyticRevenueHighest.lowest_transaction.amount = action.payload
        .lowest_transaction
        ? action.payload.lowest_transaction.amount
        : 0;
      state.analyticRevenueHighest.lowest_transaction.date = action.payload
        .lowest_transaction
        ? action.payload.lowest_transaction.date
        : "";
    },
  },
});

export const {
  changeType,
  changeTypeDetail,
  changeMonth,
  changeYear,
  changeDay,
  changeStartDate,
  changeEndDate,
} = analyticSlice.actions;

export default analyticSlice.reducer;
