import React from "react";
import {
  Navbar,
  SocialProof,
  Header,
  AboutUs,
  Offer,
  Footer,
} from "../components";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Cookies from "universal-cookie";

const Dashboard = () => {
  return (
    <>
      <Navbar />
      <Header />
      <AboutUs />
      <Offer />
      {/* <SocialProof /> */}
      <Footer />
    </>
  );
};

export default Dashboard;
