import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo-owner.svg";
import styled from "styled-components";
import Dropdown from "../assets/images/dropdown-button.svg";
import ProfilePicture from "../assets/images/mock-pp.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedOutletId,
  changeSelectedOutletName,
  changeSelectedOutletUuid,
} from "../features/outlet/outletSlice";

const NavOwner = ({ name, setShowCP, setShowLO }) => {
  const { outlet, selected_outlet_name } = useSelector((state) => state.outlet);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const ignoreClickElement = document.querySelector(".dropdown-button");
    const ignoreClickElement2 = document.querySelector(".dropdown-menu");

    window.addEventListener("click", function (e) {
      const isClickElement = ignoreClickElement.contains(e.target);
      const isClickElement2 = ignoreClickElement2.contains(e.target);

      if (!isClickElement && !isClickElement2) {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    if (outlet.length !== 0) {
      setValue([]);
      outlet.data.map((x) => {
        setValue((item) => [
          ...item,
          { outlet_uuid: x.outlet_uuid, name: x.name },
        ]);
      });
    }
  }, [outlet, selected_outlet_name]);

  return (
    <Wrapper>
      <div className="logo">
        <img src={Logo} alt="" />
        <h1>Dashboard Owner</h1>
      </div>
      <div className="right-nav">
        <div className="profile">
          <div>
            <div className="name">{name}</div>
            <div className="outlet">{selected_outlet_name}</div>
          </div>
          <img src={ProfilePicture} alt="" className="profile-picture" />
          <img
            src={Dropdown}
            alt=""
            className="dropdown-button"
            onClick={() => setShow(!show)}
          />
        </div>
        <div className={`dropdown-menu ${!show ? "hidden" : ""}`}>
          <p>Pilih warnet</p>
          <fieldset>
            {value.length !== 0 &&
              value.map((x, index) => {
                return (
                  <div key={index}>
                    <input
                      type="radio"
                      name="outlet"
                      id={value[index].outlet_uuid}
                      value={value[index].outlet_uuid}
                      checked={selected === index}
                      onChange={() => {
                        setShow(false);
                        setSelected(index);
                        dispatch(changeSelectedOutletId(value[index].id));
                        dispatch(changeSelectedOutletName(value[index].name));
                        dispatch(
                          changeSelectedOutletUuid(value[index].outlet_uuid)
                        );
                      }}
                    />
                    <label
                      htmlFor={x.outlet_uuid}
                      className={selected === index ? "active" : ""}
                      onClick={() => {
                        setShow(false);
                        setSelected(index);
                        dispatch(changeSelectedOutletId(value[index].id));
                        dispatch(changeSelectedOutletName(value[index].name));
                        dispatch(
                          changeSelectedOutletUuid(value[index].outlet_uuid)
                        );
                      }}
                    >
                      {x.name}
                    </label>
                  </div>
                );
              })}
          </fieldset>

          <div className="divider">&nbsp;</div>
          <div
            className="change-password"
            onClick={() => {
              setShowCP(true);
              setShow(false);
            }}
          >
            Ubah password
          </div>
          <div
            className="logout"
            onClick={() => {
              setShowLO(true);
              setShow(false);
            }}
          >
            Keluar
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;1,700&display=swap");
  background: #1c1d1f;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  div.logo {
    display: flex;
    margin-left: 40px;
    flex-shrink: 1;
  }
  div.logo h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 30px;
    vertical-align: middle !important;
    margin: auto 0;
    color: #e5f8ff;
    margin-left: 24px;
  }
  div.logo img {
    width: 45px;
  }
  div.right-nav {
    flex-shrink: 1;
    position: relative;
    div.profile {
      display: flex;
      color: #e5f8ff;
      margin-right: 40px;
      padding-top: 8px;
      position: relative;
      div {
        /* width: 300px; */
        text-align: right;
        margin-right: 16px;
        padding-top: 2px;
        div.name {
          font-size: 22px;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 400;
        }
        div.outlet {
          font-size: 15px;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
        }
      }
      img.profile-picture {
        border-radius: 50%;
        margin-right: 16px;
        width: 56px;
      }
      img.dropdown-button {
        width: 14px;
        cursor: pointer;
      }
    }
    div.dropdown-menu {
      color: white;
      position: absolute;
      top: 110px;
      right: 46px;
      width: 202px;
      background: #2d3139;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 16px;
      padding-bottom: 40px;
      z-index: 99;
      p {
        font-family: "Nunito Sans", sans-serif;
        font-size: 12px;
        line-height: 16px;
        color: #b0bec4;
      }
      fieldset {
        margin-top: 8px;
        div {
          margin-bottom: 8px;
        }
        label {
          font-family: "Nunito Sans", sans-serif;
          font-size: 16px;
          line-height: 22px;
          color: #e6f8ff;
          margin-left: 8px;
          margin-bottom: 8px;
          cursor: pointer;
        }
        label.active {
          color: #1f78ff;
        }
        input {
          cursor: pointer;
          background: #3d474b;
        }
      }
      div.divider {
        width: 170px;
        height: 0;
        border: 1px solid #3d474b;
        margin-top: 8px;
        margin-bottom: 16px;
      }
      div.change-password {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #e6f8ff;
        margin-bottom: 16px;
        cursor: pointer;
      }
      div.logout {
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #ed213a;
        cursor: pointer;
      }
    }
  }
`;

export default NavOwner;
