import React from "react";
import BillingIcon from "../assets/images/billing.svg";
import FoodsIcon from "../assets/images/foods.svg";
import RevenueIcon from "../assets/images/revenue.svg";
import SpecificItem from "./SpecificItem";
import styled from "styled-components";
import { useSelector } from "react-redux";

const Specific = () => {
  const { analyticRevenueHighest } = useSelector((state) => state.analytic);
  return (
    <SpecificWrapper>
      <div className="title">Spesifik</div>
      <div className="spec">
        <SpecificItem
          image={BillingIcon}
          title="Pemasukan Billing tertinggi"
          amount={analyticRevenueHighest?.highest_billing.amount}
          time={analyticRevenueHighest?.highest_billing.date.split("-")}
        />
        <SpecificItem
          image={FoodsIcon}
          title="Pemasukan Food tertinggi"
          amount={analyticRevenueHighest?.highest_food.amount}
          time={analyticRevenueHighest?.highest_food.date.split("-")}
        />
        <div className="divider"></div>
        <SpecificItem
          image={RevenueIcon}
          title="Pendapatan tertinggi"
          amount={analyticRevenueHighest?.highest_transaction.amount}
          time={analyticRevenueHighest?.highest_transaction.date.split("-")}
        />
        <SpecificItem
          image={RevenueIcon}
          title="Pendapatan terendah"
          amount={analyticRevenueHighest?.lowest_transaction.amount}
          time={analyticRevenueHighest?.lowest_transaction.date.split("-")}
        />
      </div>
    </SpecificWrapper>
  );
};

const SpecificWrapper = styled.div`
  div.spec {
    display: flex;
    fles-shrink: none;
    div.divider {
      height: 66px;
      border: 1.5px solid #303436;
      background: #303436;
      margin-top: 25px;
      margin-right: 56px;
    }
  }
`;

export default Specific;
