import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const date = new Date();

const initialState = {
  type: "month",
  day: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
  month: date.getMonth() + 1,
  year: date.getFullYear(),
  revenue: { cash: 0, qris: 0 },
  revenueDetail: { billing: 0, food: 0, refund: 0 },
  isLoading: true,
};

export const getSummary = createAsyncThunk(
  "summary/getRevenue",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/outlet/earning-cash-qris`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          type: thunkAPI.getState().summary.type,
          date: thunkAPI.getState().summary.day,
          month: thunkAPI.getState().summary.month,
          year: thunkAPI.getState().summary.year,
          start_date: thunkAPI.getState().summary.startDate,
          end_date: thunkAPI.getState().summary.endDate,
          outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
        },
      })
      .then((res) => {
        if (res.data.data !== null) return res.data.data;
        else return { cash: 0, qris: 0 };
      })
      .catch((err) => console.log(err));
  }
);

export const getSummaryDetail = createAsyncThunk(
  "summary/getRevenueDetail",
  async (state = initialState, thunkAPI) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/outlet/transaction-by-categories`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            type: thunkAPI.getState().summary.type,
            date: thunkAPI.getState().summary.day,
            month: thunkAPI.getState().summary.month,
            year: thunkAPI.getState().summary.year,
            start_date: thunkAPI.getState().summary.startDate,
            end_date: thunkAPI.getState().summary.endDate,
            outlet_uuid: thunkAPI.getState().outlet.selected_outlet_uuid,
          },
        }
      )
      .then((res) => {
        if (res.data.data !== null) return res.data.data;
        else return { billing: 0, refund: 0, food: 0 };
      })
      .catch((err) => console.log(err));
  }
);

const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    changeType: (state, { payload }) => {
      state.type = payload;
    },
    changeMonth: (state, { payload }) => {
      state.month = payload;
    },
    changeYear: (state, { payload }) => {
      state.year = payload;
    },
    changeStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    changeEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
    changeDay: (state, { payload }) => {
      state.day = payload;
    },
  },
  extraReducers: {
    [getSummary.pending]: (state) => {
      state.isLoading = true;
    },
    [getSummary.fulfilled]: (state, action) => {
      if (action.payload !== null) {
        state.revenue.cash = action.payload.cash;
        state.revenue.qris = action.payload.qris;
      }
    },
    [getSummary.rejected]: (state) => {
      state.isLoading = false;
    },
    [getSummaryDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [getSummaryDetail.fulfilled]: (state, action) => {
      if (action.payload !== null) {
        state.revenueDetail.billing = action.payload.billing;
        state.revenueDetail.food = action.payload.food;
        state.revenueDetail.refund = action.payload.refund;
      }
    },
  },
});

export const {
  changeType,
  changeMonth,
  changeYear,
  changeStartDate,
  changeEndDate,
  changeDay,
} = summarySlice.actions;

export default summarySlice.reducer;
