import React from "react";
import {
  Navbar,
  ComputerList,
  Search,
  SortGroup,
  GroupDescription,
} from "../components";

const Capacity = () => {
  return (
    <>
      <Navbar />
      <div className="md:container md:mx-auto xs:px-7 xsm:px-24 sm:px-24 md:px-40 lg:px-40 xl:px-72 2xl:px-100">
        <Search />
        <SortGroup />
        <GroupDescription />
        <ComputerList />
      </div>
    </>
  );
};

export default Capacity;
