import React from "react";
import styled from "styled-components";
import "./SocialProof.css";
import SocialProofCard from "./SocialProofCard";
import SocialProofCardList from "./SocialProofCardList";
import Spark1 from "../../assets/images/sp-bg-left.svg";

const SocialProof = () => {
  return (
    <Wrapper id="social_proof" data-aos="fade-in" data-aos-once="true">
      <div className="spark-left">&nbsp;</div>
      <h1 className="title">Pendapat mereka</h1>
      <p className="subtitle">
        Bagaimana pengalaman mereka dalam menggunakan SBS.
      </p>
      <SocialProofCardList />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-left: 118px;
  padding-right: 118px;
  padding-top: 60px;
  position: relative;
  div.spark-left {
    background: url(${Spark1}) no-repeat;
    background-size: cover;
    width: 27.77%;
    height: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  h1.title {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 52px;
    color: #303030;
  }
  p.subtitle {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 24px;
    width: 523px;
    margin-top: 16px;
    color: #808080;
  }
  @media only screen and (max-width: 600px) {
    padding: 40px 8% 0% 8%;
    h1.title {
      font-size: 32px;
    }
    p.subtitle {
      font-size: 16px;
      width: 100%;
    }
  }
`;

export default SocialProof;
