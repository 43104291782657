import React from "react";
import { useGlobalContext } from "../../context/context";
import Computer from "./Computer";
import "./Computer.css";

const ComputerList = () => {
  const { isLoading, computers, sort, sorting, computerGrouping } =
    useGlobalContext();
  if (isLoading) {
    return <div className="loading"></div>;
  }
  return (
    <div className="grid xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-5">
      {computerGrouping !== "Semua"
        ? computers
            .filter(
              (computer) =>
                computer.group.toLowerCase() === computerGrouping.toLowerCase()
            )
            .sort((a, b) => {
              try {
                if (sort.toString().toLowerCase() === "nama") {
                  if (sorting.toString().toLowerCase() === "naik")
                    return a.name.localeCompare(b.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                  else
                    return b.name.localeCompare(a.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                } else if (sort.toString().toLowerCase() === "status") {
                  if (sorting.toString().toLowerCase() === "naik")
                    return a.status.localeCompare(b.status);
                  else return b.status.localeCompare(a.status);
                } else if (sort.toString().toLowerCase() === "sisa waktu") {
                  if (sorting.toString().toLowerCase() === "turun")
                    return (
                      new Date(b.updated_at).getTime() +
                      new Date(b.duration_time * 60 * 1000).getTime() -
                      new Date().getTime() -
                      (new Date(a.updated_at).getTime() +
                        new Date(a.duration_time * 60 * 1000).getTime() -
                        new Date().getTime())
                    );
                  else
                    return (
                      new Date(a.updated_at).getTime() +
                      new Date(a.duration_time * 60 * 1000).getTime() -
                      new Date().getTime() -
                      (new Date(b.updated_at).getTime() +
                        new Date(b.duration_time * 60 * 1000).getTime() -
                        new Date().getTime())
                    );
                } else {
                  if (sorting.toString().toLowerCase() === "naik")
                    return a.name.localeCompare(b.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                  else
                    return b.name.localeCompare(a.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                }
              } catch (err) {
                console.log(err);
              }
            })
            .map((computer, index) => {
              return <Computer key={index} {...computer} />;
            })
        : computers
            .sort((a, b) => {
              try {
                if (sort.toString().toLowerCase() === "nama") {
                  if (sorting.toString().toLowerCase() === "naik")
                    return a.name.localeCompare(b.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                  else
                    return b.name.localeCompare(a.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                } else if (sort.toString().toLowerCase() === "status") {
                  if (sorting.toString().toLowerCase() === "naik")
                    return a.status.localeCompare(b.status);
                  else return b.status.localeCompare(a.status);
                } else if (sort.toString().toLowerCase() === "sisa waktu") {
                  if (sorting.toString().toLowerCase() === "turun")
                    return (
                      new Date(b.updated_at).getTime() +
                      new Date(b.duration_time * 60 * 1000).getTime() -
                      new Date().getTime() -
                      (new Date(a.updated_at).getTime() +
                        new Date(a.duration_time * 60 * 1000).getTime() -
                        new Date().getTime())
                    );
                  else
                    return (
                      new Date(a.updated_at).getTime() +
                      new Date(a.duration_time * 60 * 1000).getTime() -
                      new Date().getTime() -
                      (new Date(b.updated_at).getTime() +
                        new Date(b.duration_time * 60 * 1000).getTime() -
                        new Date().getTime())
                    );
                } else {
                  if (sorting.toString().toLowerCase() === "naik")
                    return a.name.localeCompare(b.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                  else
                    return b.name.localeCompare(a.name, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                }
              } catch (err) {
                console.log(err);
              }
            })
            .map((computer, index) => {
              return <Computer key={index} {...computer} />;
            })}
    </div>
  );
};

export default ComputerList;
