import React from "react";
import styled from "styled-components";
import ArrNext from "../assets/images/arrow-next.svg";
import DataSent from "../assets/images/data-sent.svg";
import axios from "axios";
import { useRef } from "react";
import { useState } from "react";
import useForm from "../custom-hooks/useForm";
import validate from "../utils/validateInfo";
import { addCookies, checkCookies, getCookies } from "../utils/cookies";
import Close from "../assets/images/close.svg";

const ContactUsModal = ({ values, closeModal, isModalOpen, setIsSuccess }) => {
  const sendData = async () => {
    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      address: values.address,
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/contact-us`, data)
      .then((response) => {
        closeModal();
        setIsSuccess(true);
        addCookies("SBS_CUS", new Date().toLocaleDateString("pt-PT"), 86400);
        addCookies("name", data.name, 86400);
        addCookies("email", data.email, 86400);
        addCookies("phone", data.phone, 86400);
        addCookies("address", data.address, 86400);
      })
      .catch((error) => {
        alert("Data sudah pernah dikirim!");
      });
  };
  return (
    <Wrapper className={!isModalOpen ? "hidden" : ""} id="popup">
      <div className="popup">&nbsp;</div>
      <div className="modal relative">
        {checkCookies("SBS_CUS") ? (
          <div className="close-btn bg-black relative" onClick={closeModal}>
            <img
              src={Close}
              alt=""
              className="bg-lime-50 absolute"
              style={{ top: "-24px", right: "-24px" }}
            />
          </div>
        ) : (
          ""
        )}
        {!checkCookies("SBS_CUS") ? (
          <p className="mb-8 confirmation">
            Data yang kamu masukkan akan dikirimkan ke tim kami. Apakah data
            yang kamu masukkan sudah benar?
          </p>
        ) : (
          <p className="text-center success">Data yang kamu kirim</p>
        )}

        <div className="mb-8">
          <div className="title">Nama :</div>
          <div className="content">{values.name || getCookies("name")}</div>
        </div>
        <div className="mb-8">
          <div className="title">Email :</div>
          <div className="content">{values.email || getCookies("email")}</div>
        </div>
        <div className="mb-8">
          <div className="title">What'sApp / No handphone aktif :</div>
          <div className="content">
            +62 {values.phone || getCookies("phone")}
          </div>
        </div>
        <div className="mb-8">
          <div className="title">Alamat warnet :</div>
          <div className="content">
            {values.address || getCookies("address")}
          </div>
        </div>
        {!checkCookies("SBS_CUS") ? (
          <div className="mt-20 text-center">
            <button onClick={() => closeModal()}>Ubah data</button>
            <button onClick={() => sendData()}>Ya</button>
          </div>
        ) : (
          ""
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  positon: relative;
  div.popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.6;
    z-index: 10;
  }
  div.back-btn {
    position: absolute !important;
    top: 100px;
    right: 0 !important;
  }
  /* div.back-btn img {
    position: absolute !important;
    top: 0;
    right: 0 !important;
  } */
  div.modal {
    width: 494px;
    padding: 48px;
    border: 1px solid #009eff;
    border-radius: 8px;
    background: white;
    position: fixed;
    top: 10%;
    left: 34%;
    z-index: 15;
  }
  div.modal p {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
  }
  div.modal p.confirmation {
    width: 100%;
    text-align: justify;
    font-weight: 400;
  }
  div.modal p.success {
    font-weight: 600;
    margin-bottom: 50px;
  }
  div.modal div.title {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
  }
  div.modal div.content {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
  }
  div.modal button:first-child {
    border: 2px solid #009eff;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 8px rgba(0, 158, 255, 0.5));
    border-radius: 6px;
    padding: 12px 32px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 900;
    font-size: 16px;
    color: #009eff;
    margin-right: 40px;
  }
  div.modal button:nth-child(2) {
    box-sizing: border-box;
    background: #009eff;
    box-shadow: 0px 4px 8px rgba(0, 158, 255, 0.5);
    border-radius: 6px;
    padding: 12px 32px;
    font-family: "Nunito Sanss", sans-serif;
    font-weight: 900;
    font-size: 16px;
    color: white;
    width: 144px;
  }

  @media only screen and (max-width: 600px) {
    div.popup {
      height: 1000px;
    }
    div.modal {
      width: 90%;
      top: 10%;
      left: 5%;
    }
    div.modal p {
      font-size: 14px;
    }
    div.modal div.title {
      font-size: 12px;
    }
    div.modal div.content {
      font-size: 16px;
    }
    div.modal button:first-child {
      padding: 6px 16px;
      font-size: 14px;
      margin-right: 10px;
    }
    div.modal button:nth-child(2) {
      padding: 8px 16px;
      font-size: 14px;
      width: 110px;
    }
  }
`;

export default ContactUsModal;
