import { combineReducers, configureStore } from "@reduxjs/toolkit";
import analyticReducer from "../features/analytic/analyticSlice";
import summaryReducer from "../features/summary/summarySlice";
import outletReducer from "../features/outlet/outletSlice";
import detailTransactionReducer from "../features/detail-transaction/detailTransaction";

const rootReducer = combineReducers({
  analytic: analyticReducer,
  detailTransaction: detailTransactionReducer,
  summary: summaryReducer,
  outlet: outletReducer,
});

export const store = configureStore({ reducer: rootReducer });
