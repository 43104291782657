import React, { useState } from "react";
import LineChart from "./LineChart";
import ArrowUp from "../assets/images/arrow-up-dashboard.svg";
import ArrowDown from "../assets/images/arrow-down-dashboard.svg";
import Specific from "./Specific";
import RevenueDetail from "./RevenueDetail";
import styled from "styled-components";

const SectionAnalytic = () => {
  const [showDetail, setShowDetail] = useState(true);

  return (
    <SectionWrapper>
      <LineChart />
      <div className="hide-btn" onClick={() => setShowDetail(!showDetail)}>
        {showDetail ? (
          <>
            Sembunyikan
            <img src={ArrowUp} alt="" />
          </>
        ) : (
          <>
            Lihat selengkapnya
            <img src={ArrowDown} alt="" />
          </>
        )}
      </div>
      {showDetail && (
        <>
          <RevenueDetail />
          <Specific />
        </>
      )}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: #25282e;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-top: 23px;
  padding: 40px;
  div.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #b0bec4;
  }
  div.hide-btn {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #1f78ff;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    img {
      margin-left: 4px;
    }
  }
`;

export default SectionAnalytic;
