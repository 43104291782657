import logo from "./logo.svg";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Dashboard, Error, Capacity, ContactUs, Login } from "././pages";
import "./App.css";
import Aos from "aos";
import { useEffect } from "react";
import License from "./pages/License";
import OwnerDashboard from "./pages/OwnerDashboard";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { PDFFile } from "./components";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/capacity" element={<Capacity />}></Route>
        <Route path="/contactus" element={<ContactUs />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/license" element={<License />}></Route>
        <Route path="/owner/dashboard" element={<OwnerDashboard />}></Route>
        <Route
          path="/report/:id/:type/:year/:month/:startDate"
          element={<PDFFile />}
        ></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
