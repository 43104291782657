import React from "react";
import styled from "styled-components";

const License = () => {
  return (
    <Wrapper className="md:container md:mx-auto xs:px-7">
      <h1 className="font-bold text-2xl mb-5">
        EULA (“End User License Agreement”)
      </h1>
      <h3 className="font-bold text-lg mb-5">
        PENTING – Harap Dibaca Dengan Hati-hati!!{" "}
      </h3>
      <p className="mb-5">
        EULA adalah sebuah perjanjian lisensi yang valid dan mengikat antara PT.
        Sistem Teknologi Regularisasi Elektronik Aktivitas Milenial (kami)
        dengan anda (user) untuk menggunakan aplikasi Stream Billing System
        (SBS), hal ini mencakup file yang kami berikan kepada anda yang bisa
        anda modifikasi (SBS), Berbagai pembaruan aplikasi, perbaikan aplikasi,
        dan semua copy aplikasi yang ada. Anda diharuskan untuk menyetujui
        perjanjian ini agar nantinya dapat menginstall, dan menggunakan aplikasi
        kami Stream Billing System (SBS).
      </p>
      <p className="mb-5">
        Untuk menyetujui perjanjian ini, anda harus berumur diatas 18+ (Orang
        DEWASA). Jika anda masih berumur dibawah 18 tahun, orang tua anda harus
        menyetujui perjanjian ini menggantikan anda dan mengambil semua
        pertanggungjawaban yang ada.
      </p>
      <p className="mb-5">
        Dengan mengklik tombol “I AGREE” (saya setuju) dan dengan menginstall
        dan menggunakan aplikasi kami. Anda menyatakan anda adalah seorang
        dewasa dan anda juga menyetujui untuk terikat perjanjian oleh
        persyaratan perjanjian ini dengan PT. Sistem Teknologi Regularisasi
        Elektronik Aktivitas Milenial (kami). Jika anda tidak menyetujui
        persyaratan perjanjian ini atau masih dibawah umur jangan melanjutkan
        proses instalasi yang ada.
      </p>
      <p className="mb-5">
        Dengan mengklik tombol “I AGREE” (saya setuju), anda secara tegas
        mengakui dan menyetujui untuk terikat dengan persyaratan layanan dan
        kebijakan privasi kami yang akan dijelaskan pada akhir perjanjian
        dibawah ini.
      </p>
      <ol className="ml-4">
        <li type="1">Izin Lisensi</li>
        <p>
          Anda diberikan izin terbatas, non-eksklusif, non-komersial,
          non-sublisensi, non-pengalihan, lisensi gratis untuk download,
          instalisasi, dan menggunakan aplikasi gratis di komputer anda. Anda
          diharuskan untuk membayar biaya security deposit agar bisa mendapatkan
          lisensi kami sehingga anda dapat menggunakan aplikasi kami.
        </p>
        <li type="1">Izin aplikasi</li>
        <p>
          Sebagai pertimbangan untuk penggunaan aplikasi dan penerimaan akses ke
          konten, game, pembaruan game dan aplikasi lainnya yang ada melalui
          SBS, anda setuju dan mengerti bahwa:
          <ol className="ml-4 nesting">
            <li type="1">
              Kami akan memberikan izin kepada pihak ketiga untuk menampilkan
              iklan berupa informasi tentang game, pembaruan game dan aplikasi
              lainnya. SBS menyediakan dan mengizinkan pihak ketiga untuk
              menampilkan iklan melewati aplikasi SBS. Anda mengerti dan
              menyetujui bahwa SBS atau pihak ketiga mungkin memasukkan konten
              iklan atau hal yang berhubungan dengan informasi seperti yang
              dijelaskan pada kebijakan privasi di bawah.
            </li>
            <li type="1">
              SBS akan mengizinkan pihak ketiga untuk, membuat file, aplikasi
              game, grafik dan konten lain melewati distribusi. Anda mengerti
              dan memahami bahwa konten tersebut mencakupi aplikasi pihak ketiga
              dimana diperlukan untuk mengunduh dan menginstal agar proses
              instalasi dapat diselesaikan. Jika anda memutuskan untuk tidak
              mengunduh komponen yang diperlukan atau memutuskan untuk menghapus
              konten yang diperlukan, anda mungkin tidak akan bisa untuk
              melanjutkan penggunaan aplikasi. Anda mengerti dan menyetujui
              bahwa konten apapun dapat mengumpulkan informasi dari anda,
              seperti yang dijelaskan di kebijakan privasi. Konten yang dibuat
              oleh pihak ketiga, melalui aplikasi atau website tidak dimiliki
              atau dikontrol oleh PT. Sistem Teknologi Regularisasi Elektronik
              Aktivitas Milenial. Akan tetapi perlu anda ketahui dan setujui
              bahwa memiliki kewenangan untuk menonaktifkan penggunaan dan
              menghapus konten pihak ketiga yang melanggar kebijakan, PT. Sistem
              Teknologi Regularisasi Elektronik Aktivitas Milenial tidak
              bertanggung jawab atas konten yang dipublikasi oleh public ketiga.
              Anda diharapkan untuk membaca kebijakan dan ketentuan pihak ketiga
              pada website dsb. Dengan menggunakan aplikasi, anda setuju bahwa
              PT. Sistem Teknologi Regularisasi Elektronik Aktivitas Milenial
              tidak akan bertanggung jawab dengan cara apa pun, karena konten
              tersebut berasal dari pihak ketiga.
            </li>
            <li type="1">
              Aplikasi akan memanfaatkan prosesor, bandwidth, hard drive dan
              lainnya dengan tujuan agar aplikasi dapat berjalan sebagaimana
              mestinya.
            </li>
            <li type="1">
              Aplikasi akan mengambil tindakan dan teknis yang dimaksudkan untuk
              melindungi privasi dan integritas sumber daya komputer yang anda
              gunakan. Bagaimanapun anda mengetahui dan menyetujui bahwa ini
              bukanlah jaminan dari PT. Sistem Teknologi Regularisasi Elektronik
              Aktivitas Milenial.
            </li>
            <li type="1">
              Ketika disembunyikan, aplikasi akan terus berjalan sampai anda
              melakukan konfirmasi untuk menutup aplikasi.
            </li>
            <li type="1">
              Anda dapat menghapus aplikasi kapanpun yang anda mau melalui
              control panel pada bagian “Add/Remove”. Penghapusan anda pada
              aplikasi mungkin tidak menghapus konten. Harap diingat bahwa
              dengan anda menghapus aplikasi, anda mungkin tidak dapat
              menggunakan konten aplikasi pihak ketiga yang terinstall lewat
              aplikasi.
            </li>
          </ol>
        </p>
        <li type="1">Kepemilikan</li>
        <p>
          Anda mengetahui dan menyetujui bahwa segala hal yang berhubungan
          dengan aplikasi, dokumentasi, dan properti intelektual dimiliki oleh
          kami. Oleh karena itu anda tidak memiliki hak, kepemilikan, atau
          kepentingan dalam aplikasi, dokumentasi dan hal kekayaan intelektual
          yang terkait dengannya. Anda tidak membeli aplikasi SBS tetapi hanya
          mendapatkan lisensi untuk menggunakannya sesuai dengan kebijakan yang
          berlaku. Anda tidak memperoleh property apapun dari aplikasi dan tidak
          boleh melakukan tindakan yang membahayakan, membatasi atau melakukan
          hal yang melanggar kebijakan di aplikasi kami.
        </p>
        <li type="1">Pembatasan</li>
        <p>
          Anda tidak diizinkan untuk memberikan, menjual, meminjamkan,
          menyewakan, sublisensi, mendistribusikan, memodifikasi atau memberikan
          hak kepada pihak ketiga sehubungan dengan aplikasi. Anda tidak
          diizinkan untuk membuat produk turunan berdasarkan aplikasi kami, anda
          dilarang mengeksploitasi aplikasi kami selain untuk penggunaan
          pribadi. Anda tidak diizinkan untuk menggunakan segala bentuk analisis
          ke aplikasi dengan cara “reverse engineering”, menguraikan,
          membongkar, menerjemahkan, atau mengadaptasi aplikasi kami. Anda juga
          tidak boleh mencoba untuk memulihkan code sumber dari code objek
          aplikasi. Anda tidak memiliki izin untuk mendistribusikan aplikasi
          antar jaringan dan antar instrument sendiri. Anda tidak diizinkan
          untuk memperbanyak, menyimpan file data dalam bentuk otomatis atau
          mengirim dalam bentuk apapun komponen dari dokumentasi kecuali anda
          mendapat izin dari kami. Selanjutnya tanpa persetujuan tertulis dari
          kami anda tidak boleh membiarkan, memasukkan, atau menggabungkan
          program lain ke dalam aplikasi kami.
        </p>
        <li type="1">Versi Baru Aplikasi</li>
        <p>
          Kami menyediakan pembaruan aplikasi untuk menambah fitur tambahan atau
          fungsi untuk memperbaiki program dan peningkatan fungsi aplikasi. Anda
          mengetahui dan menyetujui bahwa anda harus mengikuti pembaruan
          aplikasi agar anda mendapatkan aplikasi versi terbaik dari kami, versi
          baru aplikasi kami juga memiliki kebijakan dan ketentuan seperti yang
          kami jelaskan disini, akan tetapi kebijakan dan ketentuan tersebut
          dapat kami tambah sehubungan dengan pembaruan versi aplikasi.
        </p>
        <li>Disclaimer Garansi</li>
        <p>
          Anda mengetahui dan menyetujui bahwa penggunaan aplikasi kami adalah
          resiko anda sendiri. Kami menyangkal semua jaminan, klaim dan
          pernyataan baik tersurat, tersirat atau undang-undang termasuk tanpa
          batasan. Selanjutnya kami juga tidak menjamin bahwa aplikasi kami akan
          berfungsi tanpa gangguan, atau dapat dijalankan tanpa cacat dan
          kesalahan.
        </p>
        <li type="1">Batasan Pertanggungjawaban</li>
        <p>
          Tidak ada keadaan yang akan membuat kami bertanggung jawab atas
          kerugian tidak langsung atau konsekuensial dari anda atau pihak ketiga
          mana pun (termasuk dalam hal kehilangan data, gangguan, kehilangan
          omset, kehilangan keuntungan, gangguan bisnis, dan hilangnya informasi
          bisnis. Anda memiliki hak untuk menghapus dan menghentikan penggunaan
          aplikasi kami jika anda memiliki masalah kepuasan terhadap aplikasi
          kami.
        </p>
        <li type="1">Representasi Dan Ganti Rugi</li>
        <p>
          Anda menyatakan dan menjamin bahwa anda akan memenuhi ketentuan EULA
          ini. Selanjutnya, anda menyatakan dan menjamin bahwa anda akan setiap
          saat memenuhi kewajiban di bawah ini, serta semua undang-undang dan
          kebijakan akan berlaku untuk penggunaan aplikasi kami. Anda setuju
          untuk mengganti kerugian dan membela PT. Sistem Teknologi Regularisasi
          Elektronik Aktivitas Milenial dan afiliasinya terhadap semua kewajiban
          dan biaya, termasuk biaya pengacara yang masih wajar. Sehubungan
          dengan hal atau masalah yang timbul dari anda seperti (a) pelanggaran
          ketentuan apapun dari EULA ini atau hukum, peraturan, kebijakan, atau
          pedoman apapun yang berlaku, baik yang berlaku di sini maupun tidak,
          (b) pelanggaran hak pihak ketiga manapun, (c) penggunaan atau
          penyalahgunaan aplikasi, (d) penggunaan atau penyalahgunaan produk,
          atau manipulasi konten yang didistribusikan kepada anda melalui atau
          akibat dari penggunaan aplikasi games, update games dsb
        </p>
        <li type="1">
          Batasan ekspor dalam versi aplikasi yang ditujukan untuk Indonesia
        </li>
        <p>
          Anda mengetahui bahwa EULA ini selaras dengan undang-undang,
          ketentuan, dan Batasan lain apa pun terkait impor atau ekspor aplikasi
          atau informasi mengenai aplikasi, namun tidak terbatas pada
          undang-undang ekspor Indonesia. Anda menjamin bahwa anda akan selalu
          mematuhi undang-undang, ketentuan, dan Batasan lain yang berlaku
          terkait dengan impor atau ekspor aplikasi. Anda tidak memiliki izin
          untuk mengekspor aplikasi kami, dan informasi aplikasi kami tanpa
          persetujuan tertulis dari kami.
        </p>
        <li type="1">Hak Cipta/Merek Dagang</li>
        <p className="mb-2">
          Aplikasi SBS dilindungi oleh hak cipta yang berlaku sesuai dengan
          undang-undang dan perjanjian kekayaan intelektual lainnya. Kami secara
          tegas memiliki semua hak dalam pemberian lisensi ini. Dilarang
          menghapus, mengaburkan, atau mengubah indikasi apapun dari Salinan
          aplikasi atau dokumentasi apa pun yang merujuk pada kepemilikan PT.
          Sistem Teknologi Regularisasi Elektronik Aktivitas Milenial. Logo SBS,
          aplikasi SBS dan segala hal yang ada di aplikasi kami merupakan merek
          dagang dari PT. Sistem Teknologi Regularisasi Elektronik Aktivitas
          Milenial.
        </p>
        <p>
          Anda memahami bahwa anda tidak diberikan hak komersial, penjualan,
          penjualan Kembali, reproduksi, distribusi atau penggunaan promosi
          untuk konten pihak ketiga tersebut. Selain itu produk atau distribusi
          tidak sah dari konten pihak ketiga tersebut termasuk konten apa pun
          yang terkandung melalui aplikasi dilarang oleh hukum yang berlaku.
        </p>
        <li type="1">Penghentian Penggunaan</li>
        <p>
          EULA ini akan berlaku dimulai dari hari diinstalnya aplikasi kami.
          EULA ini hanya akan berakhir jika anda berhenti menggunakan aplikasi
          SBS kami. Anda berhak untuk mengakhiri EULA ini kapan saja dengan
          menghentikan semua penggunaan aplikasi, dokumentasi dan hak
          intelektual terkait serta menghapus segala Salinan aplikasi dari semua
          jaringan hard drive dan media penyimpanan lainnya. Kami memiliki hak
          untuk mengakhiri EULA ini kapanpun dengan alasan apapun. Kami juga
          memiliki hak untuk melakukan penghentian aplikasi yang anda lakukan
          apabila anda melanggar kebijakan dan ketentuan dari kami. Kami tidak
          bertanggung jawab terhadap kerusakan yang terjadi akibat penghentian
          EULA ini.
        </p>
        <li type="1">Hukum dan Yurisdiksi yang berlaku</li>
        <p>
          Dokumen ini akan diatur dan ditafsirkan sesuai hukum Republik
          Indonesia tanpa mempengaruhi pertentangan hukum atau ketentuan
          Republic Indonesia atau negara tempat tinggal anda sebenarnya. Semua
          perselisihan yang mungkin timbul akibat EULA ini akan secara eksklusif
          dibawa ke Pengadilan Tinggi Indonesia. Anda setuju untuk tidak
          mengajukan klaim atas hal jaksa swasta dan setuju untuk tidak
          mengajukan klaim apapun terhadap kami.
        </p>
        <li type="1">Konklusi</li>
        <p>
          Anda mengetahui dan menyetujui dengan mengklik tombol “I AGREE” (saya
          setuju), anda telah menyetujui kontrak yang mengikat secara hukum.
          Anda dengan ini menyetujui penggunaan komunikasi elektronik untuk
          membuat kontrak. EULA ini merupakan perjanjian antara anda (user) dan
          kami (PT. Sistem Teknologi Regularisasi Elektronik Aktivitas
          Milenial). Hal ini akan menggantikan semua pemahaman dan perjanjian
          sebelumnya. Kami mempunyai hak untuk memodifikasi EULA ini kapanpun.
          Jika ada ketentuan dari EULA ini yang dinyatakan tidak sah oleh hukum
          Indonesia, illegal, atau tidak dapat dilaksanakan. Ketentuan yang
          bersangkutan akan dicabut, sedangkan sisa EULA akan tetap berlaku
          sepenuhnya. Kami diizinkan atas kebijakan kami sendiri untuk
          menetapkan EULA ini atau hak apapun di bawah ini tanpa memberikan
          pemberitahuan sebelumnya.
        </p>
        <p>
          Anda memahami dan mengakui anda telah membaca EULA ini dan mengerti
          kebijakan, ketentuan, kewajiban, hak dan hal lainnya. Dengan mengklik
          tombol “I ACCEPT” (saya setuju) dan melanjutkan untuk menginstal
          aplikasi SBS, anda secara tegas setuju untuk terikat oleh syarat dan
          ketentuannya dan memberikan hak kepada PT. Sistem Teknologi
          Regularisasi Elektronik Aktivitas Milenial untuk hal-hal yang
          tercantum di sini.
        </p>
      </ol>
      <h1 className="font-bold text-2xl mb-5 mt-5">Kebijakan Privasi SBS</h1>
      <ol>
        <li type="A">Overview</li>
        <p>
          Kami menyediakan Kebijakan Privasi ini untuk memberitahu Anda tentang
          kebijakan dan prosedur kami mengenai pengumpulan, penggunaan, dan
          pengungkapan informasi pribadi dan lainnya yang kami terima atau
          dapatkan dari anda ketika menambahkan staff di aplikasi kami, dan
          mengambil informasi tentang komputer anda. Kami tidak akan
          menyebarluaskan data yang kami ambil dan menggunakan data tersebut
          sebaik-baiknya agar aplikasi kami dapat berkembang lebih baik
          kedepannya.
        </p>
        <p>
          Kami memperbarui Kebijakan Privasi ini dari waktu ke waktu. Seperti
          yang digunakan dalam Kebijakan Privasi ini, istilah "menggunakan" dan
          "memproses" informasi, menjadikan informasi tersebut untuk statistik
          atau analisis lainnya dan menggunakan atau menangani informasi dengan
          cara apa pun, tetapi tidak terbatas pada pengumpulan, penyimpanan ,
          mengevaluasi, memodifikasi, menghapus, menggunakan, menggabungkan,
          mengungkapkan dan mentransfer informasi dalam organisasi kami atau di
          antara afiliasi kami secara internasional.
        </p>
        <p>
          Penyediaan data pribadi adalah persyaratan untuk membuat akun SBS dan
          akan diproses berdasarkan hukum bahwa persetujuan diberikan oleh
          pengguna saat membuat akun SBS. Kami mengumpulkan, menggunakan, dan
          mengungkapkan informasi untuk tujuan dan berdasarkan kepentingan sah
          untuk mengelola, memantau, dan meningkatkan aplikasi SBS, fitur dan
          konten, untuk menyediakan dan meningkatkan Layanan dan memverifikasi
          bahwa pengguna Layanan memenuhi kriteria yang diperlukan. untuk
          memproses permintaan mereka. Kami melakukan ini untuk meningkatkan
          pengalaman pengguna aplikasi SBS.
        </p>
        <p>
          Dengan menerima Kebijakan Privasi ini, menggunakan Layanan kami atau
          memberikan kami Informasi Pribadi Anda (sebagaimana didefinisikan di
          bawah), Anda menyetujui pengumpulan, penggunaan, pengungkapan
          (termasuk transfer) dan berbagi Informasi Pribadi Anda sesuai dengan
          Kebijakan Privasi ini. Tolong jangan berikan kami informasi pribadi
          apapun jika Anda tidak setuju kami menggunakan Informasi Pribadi Anda
          dengan cara ini.
        </p>
        <p>
          SBS akan menyimpan data pribadi Anda dengan keamanan yang ketat. Anda
          memiliki hak untuk menarik persetujuan kapan saja, namun jika
          demikian, akun di SBS Anda akan dihapus. Anda memiliki hak untuk
          meminta akses ke, perbaikan penghapusan data pribadi kapan saja, serta
          untuk meminta pembatasan pemrosesan. Harap perhatikan bahwa mungkin
          ada alasan hukum atau alasan lain bagi kami untuk menyimpan informasi
          pribadi Anda, dalam hal ini kami akan memberi Anda pemberitahuan
          tertulis. Anda juga memiliki hak atas portabilitas data.
        </p>
        <li type="A">
          Bagaimana Kami Mengumpulkan Informasi; Jenis-Jenis Informasi yang Kami
          Kumpulkan
        </li>
        <p>
          Kami akan mengambil informasi pemilik, staff SBS, dan informasi CPU di
          internet cafe anda yang berupa: KTP, CV Staff, IP Address, Mac
          Address, VGA, Motherboard, Processor dsb.
        </p>
        <ol className="ml-4">
          <li type="1">Infomarsi Pribadi</li>
          <p>
            Kami meminta dan / atau menerima Informasi Pribadi dari Anda.
            Kadang-kadang, kami meminta Anda untuk informasi yang dapat
            diidentifikasi secara pribadi - informasi tentang Anda yang dapat
            digunakan untuk mengidentifikasi Anda sendiri atau bersama-sama
            dengan informasi yang kami miliki atau kemungkinan memiliki akses ke
            ("Informasi Pribadi") - yang termasuk, tetapi tidak terbatas pada,
            nama Anda, foto, nomor telepon, dan alamat email. Kami juga dapat
            meminta dan / atau mendapatkan informasi lain seperti usia Anda,
            jenis kelamin, dsb. Kami meminta Informasi Pribadi dalam sejumlah
            situasi, termasuk ketika Anda mendaftarkan produk, membeli atau
            mendaftar untuk suatu Layanan, mendaftar untuk Promosi, memilih
            untuk menerima pemberitahuan email, sehubungan dengan permintaan
            Anda untuk penawaran atau untuk personalisasi Layanan yang Anda
            gunakan atau ketika Anda menghubungi kami melalui Layanan, atau
            situasi lainnya. Selain itu, kami juga memperoleh Informasi Pribadi
            dengan mengumpulkan dan memelihara data tentang penggunaan Layanan
            Anda, seperti informasi tentang frekuensi, waktu dan durasi
            penggunaan, dan sejenisnya. Anda tidak harus memberikan Informasi
            Pribadi Anda, tetapi kemudian fitur-fitur tertentu yang ada di
            aplikasi kami mungkin tidak tersedia untuk Anda, atau Anda mungkin
            tidak dapat menggunakan aplikasi kami dengan efektif. Kami
            menggunakan Informasi Pribadi Anda terutama untuk menyediakan
            Layanan, menyelesaikan transaksi Anda, dan memverifikasi identitas
            Anda untuk mengelola atau menanggapi pertanyaan Anda atau mencegah
            penipuan, atau serupa. Kami juga dapat menganonimkan Informasi
            Pribadi Anda sehingga Anda tidak lagi dapat diidentifikasi darinya
            dan menghimpunnya dengan informasi tersebut yang dikumpulkan dari
            Pengguna lainnya dan dengan data log untuk mencoba memberi Anda
            pengalaman yang lebih baik, untuk meningkatkan kualitas dan nilai
            dari aplikasi SBS, Layanan, dan / atau produk kami, dan untuk
            menganalisis dan memahami bagaimana aplikasi dan / atau Layanan SBS
            digunakan. Kami juga dapat menggunakan informasi gabungan tanpa
            menggabungkannya untuk melayani Anda secara khusus, misalnya, untuk
            mengirimkan produk atau Layanan kepada Anda sesuai dengan preferensi
            atau batasan Anda.
          </p>
        </ol>
        <li type="A">Meninjau, Memperbarui, atau Menghapus Informasi Anda</li>
        <p>
          Setiap pengguna dapat meninjau, memperbarui, memperbaiki atau meminta
          kami menghapus Informasi Pribadi yang dia sediakan untuk atau selama
          penggunaan Layanan dengan menghubungi CS kami Jika Anda meminta kami
          sepenuhnya menghapus semua informasi tersebut, maka akun Anda untuk
          penggunaan Layanan dapat dinonaktifkan, sebagaimana berlaku, dan Anda
          mungkin tidak lagi dapat menggunakan atau mengakses Layanan. Kami
          dapat menyimpan salinan Informasi Pribadi Anda yang diarsipkan
          sebagaimana diharuskan oleh hukum atau untuk tujuan bisnis yang sah.
        </p>
        <li type="A">Phishing</li>
        <p>
          Pencurian identitas dan praktik yang saat ini dikenal sebagai
          "phishing" menjadi perhatian besar bagi Kami. Menjaga informasi untuk
          membantu melindungi Anda dari pencurian identitas adalah prioritas
          utama. Kami tidak dan tidak akan, kapan saja, meminta informasi kartu
          kredit Anda, informasi login, atau nomor identifikasi nasional melalui
          penggunaan email yang tidak aman atau tidak diminta atau melalui
          komunikasi telepon yang kami mulai tanpa penyelidikan sebelumnya dari
          Anda atau pengaturan sebelumnya dengan Anda.
        </p>
        <li type="A">Keamanan</li>
        <p>
          SBS sangat peduli dengan menjaga informasi Anda. Kami menerapkan
          langkah-langkah keamanan fisik, teknologi, dan administrasi yang wajar
          untuk mencegah akses, pengumpulan, penggunaan, pengungkapan,
          penyalinan, modifikasi, pembuangan, atau risiko serupa yang serupa ke
          informasi Anda. Penting untuk dipahami, bagaimanapun, bahwa tidak ada
          aplikasi, server, transmisi internet, atau metode penyimpanan
          elektronik atau lainnya yang 100% aman atau "bukti hacker." Karena
          itu, sementara kami berusaha melindungi informasi pribadi Anda, kami
          tidak dapat menjamin keamanan mutlaknya. Kami akan membuat
          pengungkapan apa pun yang diperlukan secara hukum atas pelanggaran
          keamanan, kerahasiaan, atau integritas "data pribadi" Anda yang
          disimpan secara elektronik (sebagaimana didefinisikan dalam
          undang-undang negara bagian yang berlaku tentang pemberitahuan
          pelanggaran keamanan) kepada Anda melalui email atau posting yang
          mencolok di waktu yang paling tepat dan tanpa penundaan yang tidak
          masuk akal, sejauh konsisten dengan (i) kebutuhan sah penegakan hukum
          atau (ii) tindakan yang diperlukan untuk menentukan ruang lingkup
          pelanggaran dan mengembalikan integritas sistem data yang wajar.
        </p>
        <li>Informasi lainnya</li>
        <p>
          Kebijakan privasi ini dapat diubah atau ditambah sewaktu-waktu. Setiap
          perubahan akan ditampilkan di halaman ini. Oleh karenanya, kami
          menghimbau Anda untuk mengakses halaman ini secara berkala untuk
          mengetahui apabila ada perubahan. Dengan menggunakan layanan ini,
          pengguna dianggap telah menyetujui ketentuan kebijakan privasi ini.
          Apabila pengguna tidak setuju dengan kebijakan privasi ini, pengguna
          dipersilahkan untuk berhenti menggunakan layanan SBS.
        </p>
        <li type="A">Keluhan</li>
        <p>
          Jika Anda memiliki masalah atau keluhan tentang pelanggaran privasi
          Anda atau cara kami menangani informasi pribadi Anda, silakan kirim
          email kepada kami di CS kami dan informasikan kepada kami tentang
          sifat keluhan Anda. Kami kemudian akan menyelidiki keluhan Anda dan
          berusaha memberikan respons kami dalam waktu yang wajar (antara 30-90
          hari tergantung pada hukum yurisdiksi Anda) untuk menerima permintaan
          Anda. Jika, setelah menerima tanggapan kami, Anda masih menganggap
          bahwa keluhan privasi Anda masih belum terselesaikan, maka Anda dapat,
          misalnya, merujuk kekhawatiran Anda ke regulator privasi di negara
          tempat Anda tinggal.
        </p>
      </ol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  p {
    text-align: justify;
  }
  ol.nesting li:before {
  }
`;

export default License;
