import React, { useState, useEffect } from "react";
import FilterIcon from "../assets/images/filter-range.svg";
import FilterIconLight from "../assets/images/filter-range-light.svg";
import ArrNext from "../assets/images/arrow-next-filter.svg";
import ArrPrev from "../assets/images/arrow-prev-filter.svg";
import DatePicker from "react-date-picker";
import Calendar from "react-calendar";
import styled from "styled-components";
import ExportIcon from "../assets/images/export-pdf.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  changeType,
  changeTypeDetail,
  changeYear,
  changeMonth,
  getAnalytic,
  getAnalyticDetail,
  getAnalyticMethod,
  getAnalyticHighest,
} from "../features/analytic/analyticSlice";
import {
  changeType as changeTypeTransaction,
  changeMonth as changeMonthTransaction,
  changeYear as changeYearTransaction,
  changeStartDate as changeStartDateTransaction,
  changeEndDate as changeEndDateTransaction,
  changePage,
  getDetailTransaction,
  getDetailTransactionCategory,
  getDetailTransactionMethod,
  getHighestRevenue,
  getHighestSelling,
} from "../features/detail-transaction/detailTransaction";

const FilterAnalytic = ({ tab }) => {
  const dispatch = useDispatch();
  const { selected_outlet_id } = useSelector((state) => state.outlet);
  const { startDate, type, year, month } = useSelector(
    (state) => state.detailTransaction
  );
  const date = new Date();
  const [monthFilter, setMonthFilter] = useState(date.getMonth());
  const [yearFilter, setYearFilter] = useState(date.getFullYear());
  const [monthFilterShow, setMonthFilterShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [detailIndex, setDetailIndex] = useState(0);

  const [yearFilterShow, setYearFilterShow] = useState(false);
  const [selectedYear, setSelectedYear] = useState(date.getFullYear());
  const [startYear, setStartYear] = useState(
    selectedYear % 10 === 0 ? selectedYear : selectedYear - (selectedYear % 10)
  );
  const [endYear, setEndYear] = useState(startYear + 9);
  const [yearList, setYearList] = useState([]);

  const [dateFilterDet, setDateFilterDet] = useState(new Date());
  const [monthFilterDet, setMonthFilterDet] = useState(date.getMonth());
  const [yearFilterDet, setYearFilterDet] = useState(date.getFullYear());
  const [dateFilterDetShow, setDateFilterDetShow] = useState(false);
  const [monthFilterDetShow, setMonthFilterDetShow] = useState(false);

  const getPreviousListYear = () => {
    setStartYear(startYear - 10);
    setEndYear(endYear - 10);
  };

  const getNextListYear = () => {
    setStartYear(startYear + 10);
    setEndYear(endYear + 10);
  };

  useEffect(() => {
    var array = [];
    var i;
    for (i = parseInt(startYear); i <= parseInt(endYear); i++) {
      array.push(i);
    }
    setYearList([]);
    setYearList(array);
  }, [startYear]);

  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  const getMonthly = () => {
    setIndex(0);
    dispatch(changeType("monthly"));
    dispatch(changeTypeDetail("yearly"));
    dispatch(changeYear(date.getFullYear()));
    dispatch(getAnalytic());
    dispatch(getAnalyticDetail());
    dispatch(getAnalyticMethod());
    dispatch(getAnalyticHighest());
  };

  const geFiltertMonthly = (year) => {
    setSelectedYear(year);
    dispatch(changeType("monthly"));
    dispatch(changeTypeDetail("yearly"));
    dispatch(changeYear(year));
    dispatch(getAnalytic());
    dispatch(getAnalyticDetail());
    dispatch(getAnalyticMethod());
    dispatch(getAnalyticHighest());
  };

  const getDaily = () => {
    setIndex(1);
    dispatch(changeType("daily"));
    dispatch(changeTypeDetail("daily"));
    dispatch(changeMonth(monthFilter + 1));
    dispatch(changeYear(yearFilter));
    dispatch(getAnalytic());
    dispatch(getAnalyticDetail());
    dispatch(getAnalyticMethod());
    dispatch(getAnalyticHighest());
  };

  const getFilterMonthRev = (month) => {
    setMonthFilter(month);
    dispatch(changeType("daily"));
    dispatch(changeTypeDetail("daily"));
    dispatch(changeMonth(month + 1));
    dispatch(changeYear(yearFilter));
    dispatch(getAnalytic());
    dispatch(getAnalyticDetail());
    dispatch(getAnalyticMethod());
    dispatch(getAnalyticHighest());
  };

  const getFilterYearRev = (year) => {
    setYearFilter(year);
    dispatch(changeType("daily"));
    dispatch(changeTypeDetail("daily"));
    dispatch(changeMonth(monthFilter + 1));
    dispatch(changeYear(year));
    dispatch(getAnalytic());
    dispatch(getAnalyticDetail());
    dispatch(getAnalyticMethod());
    dispatch(getAnalyticHighest());
  };

  const getYearly = () => {
    setIndex(2);
    dispatch(changeType("yearly"));
    dispatch(changeTypeDetail("yearly"));
    dispatch(getAnalytic());
    dispatch(getAnalyticDetail());
    dispatch(getAnalyticMethod());
    dispatch(getAnalyticHighest());
  };

  const getThisMonthDetail = () => {
    setDetailIndex(0);
    dispatch(changeTypeTransaction("month"));
    dispatch(changeMonthTransaction(date.getMonth() + 1));
    dispatch(changeYearTransaction(date.getFullYear()));
    dispatch(changePage(1));
    dispatch(getDetailTransaction());
    dispatch(getDetailTransactionCategory());
    dispatch(getDetailTransactionMethod());
    dispatch(getHighestRevenue());
    dispatch(getHighestSelling());
  };
  const getLastMonthDetail = () => {
    setDetailIndex(1);
    dispatch(changeTypeTransaction("month"));
    dispatch(changeMonthTransaction(date.getMonth()));
    dispatch(changeYearTransaction(date.getFullYear()));
    dispatch(changePage(1));
    dispatch(getDetailTransaction());
    dispatch(getDetailTransactionCategory());
    dispatch(getDetailTransactionMethod());
    dispatch(getHighestRevenue());
    dispatch(getHighestSelling());
  };
  const getFilterYearDetail = (year) => {
    setYearFilterDet(year);
    setDetailIndex(2);
    dispatch(changeTypeTransaction("month"));
    dispatch(changeYearTransaction(year));
    dispatch(changePage(1));
    dispatch(getDetailTransaction());
    dispatch(getDetailTransactionCategory());
    dispatch(getDetailTransactionMethod());
    dispatch(getHighestRevenue());
    dispatch(getHighestSelling());
  };
  const getFilterMonthDetail = (month) => {
    setMonthFilterDet(month);
    setDetailIndex(2);
    dispatch(changeTypeTransaction("month"));
    dispatch(changeMonthTransaction(month + 1));
    dispatch(changePage(1));
    dispatch(getDetailTransaction());
    dispatch(getDetailTransactionCategory());
    dispatch(getDetailTransactionMethod());
    dispatch(getHighestRevenue());
    dispatch(getHighestSelling());
  };
  const getFilterDateDetail = (date) => {
    setDateFilterDet(date);
    setDetailIndex(3);
    dispatch(changeTypeTransaction("date"));
    dispatch(
      changeStartDateTransaction(
        date.getFullYear() +
          "-" +
          (parseInt(date.getMonth()) + 1) +
          "-" +
          date.getDate()
      )
    );
    dispatch(
      changeEndDateTransaction(
        date.getFullYear() +
          "-" +
          (parseInt(date.getMonth()) + 1) +
          "-" +
          date.getDate()
      )
    );
    dispatch(changePage(1));
    dispatch(getDetailTransaction());
    dispatch(getDetailTransactionCategory());
    dispatch(getDetailTransactionMethod());
    dispatch(getHighestRevenue());
    dispatch(getHighestSelling());
  };

  useEffect(() => {
    // const ignoreClickOnElementa = document.querySelector(".filter-year-btn");
    // const ignoreClickOnElement2a = document.querySelector(".select-year");
    // window.addEventListener("click", function (e) {
    //   const isClickOnElementa =
    //     e.target === ignoreClickOnElementa ? true : false;
    //   const isClickOnElement2a = ignoreClickOnElement2a.contains(e.target);
    //   if (!isClickOnElementa && !isClickOnElement2a) {
    //     setYearFilterShow(false);
    //   }
    // });
  }, []);

  return (
    <FilterWrapper>
      {tab === 0 ? (
        <>
          <ul>
            <li className={index === 0 ? "active" : ""} onClick={getMonthly}>
              Bulanan
            </li>
            <li className={index === 1 ? "active" : ""} onClick={getDaily}>
              Harian
            </li>
            <li className={index === 2 ? "active" : ""} onClick={getYearly}>
              Tahunan
            </li>
          </ul>
          <div className="filter">
            {index === 0 && (
              <div className="filter-year">
                <div
                  className="filter-year-btn"
                  onClick={() => setYearFilterShow(!yearFilterShow)}
                >
                  Tahun {selectedYear}
                </div>
                {yearFilterShow && (
                  <div className="select-year select-year-analytic">
                    <input
                      type="text"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.currentTarget.value)}
                    />
                    <div className="year-range">
                      {startYear}-{endYear}
                    </div>
                    <div className="filter-button">
                      <img
                        src={ArrPrev}
                        alt=""
                        onClick={() => getPreviousListYear()}
                      />
                      <img
                        src={ArrNext}
                        alt=""
                        onClick={() => getNextListYear()}
                      />
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          {yearList.map((value, index) => {
                            return value < startYear + 5 ? (
                              value === selectedYear ? (
                                <td
                                  key={index}
                                  className="active"
                                  onClick={() => geFiltertMonthly(value)}
                                >
                                  {value}
                                </td>
                              ) : (
                                <td
                                  key={index}
                                  onClick={() => geFiltertMonthly(value)}
                                >
                                  {value}
                                </td>
                              )
                            ) : (
                              <td key={index} hidden={true}></td>
                            );
                          })}
                        </tr>
                        <tr>
                          {yearList.map((value, index) => {
                            return value >= startYear + 5 ? (
                              value === selectedYear ? (
                                <td
                                  key={index}
                                  className="active"
                                  onClick={() => geFiltertMonthly(value)}
                                >
                                  {value}
                                </td>
                              ) : (
                                <td
                                  key={index}
                                  onClick={() => geFiltertMonthly(value)}
                                >
                                  {value}
                                </td>
                              )
                            ) : (
                              <td key={index} hidden={true}></td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
            {index === 1 && (
              <div className="filter-month">
                <div
                  className="filter-month-btn"
                  onClick={() => {
                    setMonthFilterShow(!monthFilterShow);
                    getFilterMonthRev(monthFilter);
                  }}
                >
                  {monthName[monthFilter] + " " + yearFilter}
                </div>
                <div
                  className={`${
                    monthFilterShow === true
                      ? "select-month"
                      : "hide select-month"
                  }`}
                >
                  <div className="year-list">{yearFilter}</div>
                  <div className="filter-button">
                    <img
                      src={ArrPrev}
                      alt=""
                      onClick={() => getFilterYearRev(yearFilter - 1)}
                    />
                    <img
                      src={ArrNext}
                      alt=""
                      onClick={() => getFilterYearRev(yearFilter + 1)}
                    />
                  </div>
                  <div className="month-list">
                    <div
                      className={`month-list-item ${
                        monthFilter === 0 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(0)}
                    >
                      Jan
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 1 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(1)}
                    >
                      Feb
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 2 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(2)}
                    >
                      Mar
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 3 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(3)}
                    >
                      Apr
                    </div>
                  </div>
                  <div className="month-list">
                    <div
                      className={`month-list-item ${
                        monthFilter === 4 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(4)}
                    >
                      Mei
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 5 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(5)}
                    >
                      Jun
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 6 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(6)}
                    >
                      Jul
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 7 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(7)}
                    >
                      Agu
                    </div>
                  </div>
                  <div className="month-list">
                    <div
                      className={`month-list-item ${
                        monthFilter === 8 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(8)}
                    >
                      Sep
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 9 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(9)}
                    >
                      Okt
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 10 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(10)}
                    >
                      Nov
                    </div>
                    <div
                      className={`month-list-item ${
                        monthFilter === 11 ? "active" : ""
                      }`}
                      onClick={() => getFilterMonthRev(11)}
                    >
                      Des
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <ul>
            <li
              className={detailIndex === 0 ? "active" : ""}
              onClick={getThisMonthDetail}
            >
              Bulan ini
            </li>
            <li
              className={detailIndex === 1 ? "active" : ""}
              onClick={getLastMonthDetail}
            >
              Bulan lalu
            </li>
          </ul>
          <div className="detail-filter">
            <div className="filter-month">
              <div
                className={`filter-month-btn ${
                  detailIndex === 2 ? "active" : ""
                }`}
                onClick={() => {
                  setMonthFilterDetShow(!monthFilterDetShow);
                  getFilterMonthDetail(monthFilterDet);
                }}
              >
                {detailIndex == 2
                  ? `${monthName[monthFilterDet]} ${yearFilterDet}`
                  : "Pilih bulan"}
              </div>
              <div
                className={`${
                  monthFilterDetShow === true
                    ? "select-month"
                    : "hide select-month"
                }`}
              >
                <div className="year-list">{yearFilterDet}</div>
                <div className="filter-button">
                  <img
                    src={ArrPrev}
                    alt=""
                    onClick={() => getFilterYearDetail(yearFilterDet - 1)}
                  />
                  <img
                    src={ArrNext}
                    alt=""
                    onClick={() => getFilterYearDetail(yearFilterDet + 1)}
                  />
                </div>
                <div className="month-list">
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 0 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(0)}
                  >
                    Jan
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 1 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(1)}
                  >
                    Feb
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 2 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(2)}
                  >
                    Mar
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 3 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(3)}
                  >
                    Apr
                  </div>
                </div>
                <div className="month-list">
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 4 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(4)}
                  >
                    Mei
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 5 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(5)}
                  >
                    Jun
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 6 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(6)}
                  >
                    Jul
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 7 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(7)}
                  >
                    Agu
                  </div>
                </div>
                <div className="month-list">
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 8 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(8)}
                  >
                    Sep
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 9 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(9)}
                  >
                    Okt
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 10 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(10)}
                  >
                    Nov
                  </div>
                  <div
                    className={`month-list-item ${
                      monthFilterDet === 11 ? "active" : ""
                    }`}
                    onClick={() => getFilterMonthDetail(11)}
                  >
                    Des
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detail-filter detail-filter-date">
            <div className="filter-date">
              <div
                className={`filter-date-btn ${
                  detailIndex == 3 ? "active" : ""
                }`}
                onClick={() => {
                  setDateFilterDetShow(!dateFilterDetShow);
                  // getFilterDateDetail(dateFilterDet);
                }}
              >
                {detailIndex == 3
                  ? `${dateFilterDet.getDate()} ${
                      monthName[dateFilterDet.getMonth()]
                    } ${dateFilterDet.getFullYear()} 
                `
                  : "Pilih tanggal"}
              </div>
              <div id="calendar" className={!dateFilterDetShow ? "hidden" : ""}>
                <Calendar
                  onChange={(item) => getFilterDateDetail(item)}
                  value={dateFilterDet}
                  locale={"id"}
                />
              </div>
            </div>
          </div>
          <Link
            to={{
              pathname: `/report/${selected_outlet_id}/${type}/${year}/${month}/${startDate}`,
              state: [selected_outlet_id],
            }}
            target="_blank"
          >
            <div className="export">
              Export PDF{" "}
              <img src={ExportIcon} alt="" className="inline-block" />
            </div>
          </Link>
        </>
      )}
    </FilterWrapper>
  );
};

const FilterWrapper = styled.div`
  display: inline-block;
  @media screen and (max-width: 1024px) {
    display: block;
    ul {
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }
  div.filter {
    margin-left: 48px;
    display: inline-block;
    div.filter-year {
      display: inline-block;
      position: relative;
      div.filter-year-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 8px;
        height: 31px;
        background: #1f78ff;
        border-radius: 20px;
        flex: none;
        order: 0;
        flex-grow: 0;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        cursor: pointer;
      }
      div.filter-year-btn::before {
        content: url(${FilterIconLight});
      }
      div.select-year {
        position: absolute;
        width: 249px !important;
        top: 40px;
        background: #2d3139;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 16px;
        input {
          width: 92px;
          height: 32px;
          background: #111518;
          border-radius: 4px;
          color: #b0bec4;
          padding: 8px;
          display: block;
        }
        div.year-range {
          display: inline-block;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #e6f8ff;
          margin-top: 16px;
        }
        div.filter-button {
          display: inline-block;
          margin-left: 111px;
          img {
            display: inline-block;
            margin-left: 13px;
            cursor: pointer;
          }
        }
        table {
          margin-top: 32px;
          td {
            font-family: "Nunito Sans", sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #b0bec4;
            padding-bottom: 24px;
            cursor: pointer;
          }
          td: not(: last-child) {
            padding-right: 14px;
          }
          td.active {
            font-family: "Nunito Sans", sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #1f78ff;
          }
        }
      }
    }
    div.filter-month {
      display: inline-block;
      div.filter-month-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 8px;
        width: 124px;
        height: 31px;
        background: #1f78ff;
        border-radius: 20px;
        flex: none;
        order: 0;
        flex-grow: 0;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 700;
        font-size: 12px !important;
        line-height: 19px;
        color: #ffffff;
        cursor: pointer;
      }
      div.filter-month-btn::before {
        content: url(${FilterIconLight});
      }
      position: relative;
      div.select-month.hide {
        display: none;
      }
      div.select-month {
        position: absolute;
        top: 40px;
        left: -45px;
        background: #2d3139;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        width: 212px;
        padding: 24px;
        div.filter-button {
          display: inline-block;
          margin-left: 90px;
          img {
            display: inline-block;
            margin-left: 13.36px;
            cursor: pointer;
          }
        }
        div.year-list {
          display: inline-block;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
          font-size: 12px;
          color: #e5f8ff;
        }
        div.month-list {
          margin-top: 12px;
          div.month-list-item {
            display: inline-block;
            font-family: "Nunito Sans", sans-serif;
            font-size: 12px;
            line-height: 16px;
            color: #b0bec4;
            cursor: pointer;
            width: 23px;
          }
          div.month-list-item: not(: last-child) {
            margin-right: 24px;
          }
          div.month-list-item.active {
            font-weight: 700;
            color: #1f78ff;
          }
        }
      }
    }
  }
  ul {
    margin-left: 24px;
    display: inline-block;
  }
  li {
    display: inline-block;
    padding: 6px 20px;
    border: 1px solid #b0bec4;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
    border-radius: 16px;
    color: #b0bec4;
    margin-right: 16px;
    line-height: 16px;
    height: 31px;
    cursor: pointer;
  }
  li.active,
  li:hover {
    background: #1f78ff;
    border: 1px solid #1f78ff;
    color: #fff;
  }
  div.detail-filter.detail-filter-date {
    margin-left: 48px;
    div.filter-date {
      position: relative;
      div.filter-date-btn {
        font-size: 14px;
      }
      div.filter-date-btn.active,
      div.filter-date-btn:hover {
        font-weight: 700;
        color: #ffffff;
        background: #1f78ff;
        border-color: #1f78ff;
      }
      div.filter-date-btn::before {
        content: url(${FilterIcon});
      }
      div.filter-date-btn:hover::before,
      div.filter-date-btn.active::before {
        content: url(${FilterIconLight});
      }
      div.filter-date-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 8px;
        height: 31px;
        border: 1px solid #b0bec4;
        border-radius: 20px;
        flex: none;
        order: 0;
        flex-grow: 0;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
        color: #b0bec4;
        line-height: 19px;
        cursor: pointer;
      }
      div#calendar {
        position: absolute;
        top: 40px;
        z-index: 99;
        div.react-calendar {
          background: rgba(45, 49, 57, 1);
          border: none;
          border-radius: 8px;
          .react-calendar__navigation__label,
          .react-calendar__navigation__arrow {
            color: #e6f8ff;
            background: rgba(45, 49, 57, 1) !important;
          }
          .react-calendar__navigation__label:hover,
          .react-calendar__navigation__arrow:hover {
            background: rgba(45, 49, 57, 1) !important;
          }
          .react-calendar__month-view__weekdays__weekday,
          .react-calendar__month-view__days__day--neighboringMonth {
            color: #666666 !important;
          }
          .react-calendar__month-view__days__day {
            color: #b0bec4;
          }
        }
      }
    }
  }
  div.detail-filter {
    display: inline-block;
    div.filter-month {
      position: relative;
      div.filter-month-btn.active,
      div.filter-month-btn:hover {
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;
        background: #1f78ff;
        border-color: #1f78ff;
      }
      div.filter-month-btn::before {
        content: url(${FilterIcon});
      }
      div.filter-month-btn:hover::before,
      div.filter-month-btn.active::before {
        content: url(${FilterIconLight});
      }
      div.filter-month-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 8px;
        height: 31px;
        border: 1px solid #b0bec4;
        border-radius: 20px;
        flex: none;
        order: 0;
        flex-grow: 0;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
        color: #b0bec4;
        line-height: 19px;
        cursor: pointer;
      }
      div.select-month.hide {
        display: none;
      }
      div.select-month {
        position: absolute;
        top: 40px;
        background: #2d3139;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        width: 212px;
        padding: 24px;
        z-index: 99;
        div.filter-button {
          display: inline-block;
          margin-left: 90px;
          img {
            display: inline-block;
            margin-left: 13.36px;
            cursor: pointer;
          }
        }
        div.year-list {
          display: inline-block;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
          font-size: 12px;
          color: #e5f8ff;
        }
        div.month-list {
          margin-top: 12px;
          div.month-list-item {
            display: inline-block;
            font-family: "Nunito Sans", sans-serif;
            font-size: 12px;
            line-height: 16px;
            color: #b0bec4;
            cursor: pointer;
            width: 23px;
          }
          div.month-list-item: not(: last-child) {
            margin-right: 24px;
          }
          div.month-list-item.active {
            font-weight: 700;
            color: #1f78ff;
          }
        }
      }
    }
  }
  div.export {
    display: inline-block;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1f78ff;
    cursor: pointer;
    position: absolute;
    right: 100px;
  }
`;

export default FilterAnalytic;
