import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <Wrapper>
      <div>
        <h1>404</h1>
        <h3>sorry, the page you tried cannot be found</h3>
        <Link to="/" className="btn">
          back home
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: hsl(186, 100%, 94%);
  text-align: center;
  h1 {
    font-size: 10rem;
  }
  h3 {
    color: hsl(209, 34%, 30%);
    margin-bottom: 1, 5rem;
  }
  .btn {
    text-transform: uppercase;
    background: hsl(185, 62%, 45%);
    color: white;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-weight: 400;
    font-size: 0.875rem;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 0.25rem;
    letter-spacing: 0.1rem;
    transition: all 0.3s linear;
    text-decoration: none;
  }
`;

export default Error;
