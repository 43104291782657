import Cookies from "universal-cookie";

const addCookies = (name, value, time) => {
  const cookies = new Cookies();
  cookies.set(name, value, {
    maxAge: time,
  });
};

const checkCookies = (name) => {
  const cookies = new Cookies();
  if (cookies.get(name)) return true;
  else return false;
};

const getCookies = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export { addCookies, checkCookies, getCookies };
