import React from "react";

const OfferList = ({ activeOffer, index, value, handleClick }) => {
  return (
    <>
      <li
        className={`grid grid-cols-6  ${
          activeOffer === index + 1 ? "active" : ""
        }`}
        onClick={() => handleClick(index + 1)}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="" key={"a-" + index}>
          <img src={value.imgTitle} alt={value.title} className="mr-8" />
        </div>
        <div className="col-span-5">{value.title}</div>
      </li>
      <li
        className={activeOffer === index + 1 ? "accordion show" : "accordion"}
        key={"nsafd -" + index}
      >
        <div key={"d-" + index}>
          <img src={value.imgContent} alt="" key={"e-" + index} />
          <p key={"f-" + index}>{value.content1}</p>
          <p key={"g-" + index}>{value.content2}</p>
        </div>
      </li>
    </>
  );
};

export default OfferList;
