import React, { useEffect, useRef, useState } from "react";
import Sort from "../../assets/images/sort.svg";
import Group from "../../assets/images/group.svg";
import Select from "react-select";
import "./SortGroup.css";
import { useGlobalContext } from "../../context/context";

const SortGroup = () => {
  const {
    setSort,
    sorting,
    setSorting,
    computerGroups,
    setComputerGrouping,
    computerGroupColor,
  } = useGlobalContext();
  const [isActive, setIsActive] = useState(false);
  const [isActiveGroup, setIsActiveGroup] = useState(false);
  const selectedText = useRef("Nama");
  const selectedGroup = useRef("");
  const wrapperRef = useRef(null);
  const selectOption = (e) => {
    selectedText.current.innerText = e;
    setSort(e);
  };

  const selectGroup = (e) => {
    selectedGroup.current.innerText = e;
    setComputerGrouping(e);
  };

  const handleToggle = (data) => {
    if (data === "sort") setIsActive(!isActive);
    else setIsActiveGroup(!isActiveGroup);
  };

  function useOutsideAlerter(ref, condition) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (condition) setIsActive(!isActive);
          console.log(condition);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="mt-12 mb-7" ref={wrapperRef}>
      <div className="relative inline-block">
        <img src={Sort} alt="" className="xs:w-4 sm:w-5 inline-block mr-2" />
        <p className="inline-block mr-2 xs:text-xs sm:text-sm ">Urutkan : </p>
        <div className="selector inline-block">
          <div id="selectField">
            <p
              id="selectText"
              ref={selectedText}
              onClick={() => handleToggle("sort")}
              className="font-bold text-sm"
            >
              Nama
            </p>
          </div>
          <ul
            className={!isActive ? "hide" : "list absolute top-8 left-20 z-10"}
          >
            <li
              className={
                selectedText.current.innerText === "Nama"
                  ? "options font-bold text-sm selected"
                  : "options text-sm"
              }
              onClick={(e) => {
                selectOption(e.target.innerText);
                handleToggle("sort");
              }}
            >
              Nama
            </li>
            <li
              className={
                selectedText.current.innerText === "Status"
                  ? "options font-bold text-sm selected"
                  : "options text-sm"
              }
              onClick={(e) => {
                selectOption(e.target.innerText);
                handleToggle("sort");
              }}
            >
              Status
            </li>
            <li
              className={
                selectedText.current.innerText === "Sisa waktu"
                  ? "options font-bold text-sm selected"
                  : "options text-sm"
              }
              onClick={(e) => {
                selectOption(e.target.innerText);
                handleToggle("sort");
              }}
            >
              Sisa waktu
            </li>
            <div className="px-3 py-1.5">
              <hr />
            </div>
            <li
              className={
                sorting === "Naik"
                  ? "options font-bold text-sm selected"
                  : "options text-sm"
              }
              onClick={(e) => {
                setSorting(e.target.innerText);
                handleToggle("sort");
              }}
            >
              Naik
            </li>
            <li
              className={
                sorting === "Turun"
                  ? "options font-bold text-sm selected"
                  : "options text-sm"
              }
              onClick={(e) => {
                setSorting(e.target.innerText);
                handleToggle("sort");
              }}
            >
              Turun
            </li>
          </ul>
        </div>
      </div>
      <div className="relative inline-block ml-10">
        <img src={Group} alt="" className="xs:w-4 sm:w-5 inline-block mr-2" />
        <p className="inline-block mr-2 xs:text-xs sm:text-sm">Grup : </p>
        <div className="selector inline-block">
          <div id="selectField">
            <p
              id="selectGroup"
              ref={selectedGroup}
              onClick={() => handleToggle()}
              className="font-bold text-sm"
            >
              Semua
            </p>
          </div>
          <ul
            className={
              !isActiveGroup ? "hide" : "list absolute top-8 left-11 z-10"
            }
          >
            <li
              className={
                selectedGroup.current.innerText === "Semua"
                  ? "options font-bold text-sm selected"
                  : "options text-sm"
              }
              onClick={(e) => {
                selectGroup("Semua");
                handleToggle();
              }}
            >
              <div className="w-3 h-3 inline-block align-middle mr-1.5 rounded-sm"></div>
              Semua
            </li>
            {computerGroups.map((data, index) => {
              return (
                <li
                  className={
                    selectedGroup.current.innerText === data.name
                      ? "options font-bold text-sm selected"
                      : "options text-sm"
                  }
                  onClick={(e) => {
                    selectGroup(data.name);
                    handleToggle();
                  }}
                  key={index}
                >
                  <div
                    className="w-3 h-3 inline-block align-middle mr-1.5 rounded-sm"
                    style={{ background: computerGroupColor[data.name] }}
                  ></div>
                  {data.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SortGroup;
