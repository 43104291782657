import React, { useState } from "react";
import styled from "styled-components";
import DetailTransactionOwner from "../DetailTransactionOwner";
import "./AnalyticOwner.css";
import FilterAnalytic from "../FilterAnalytic";
import SectionAnalytic from "../SectionAnalytic";

const AnalyticOwner = ({
  highestSell,
  detTransRevDetail,
  detTransRev,
  detHighestRev,
}) => {
  const [tab, setTab] = useState(0);

  const handleChangeTab = (tab) => {
    setTab(tab);
  };
  return (
    <Wrapper>
      <div
        className={`menu ${tab === 0 ? "active" : ""}`}
        onClick={() => handleChangeTab(0)}
      >
        Analitik
      </div>
      <div
        className={`menu ${tab === 1 ? "active" : ""}`}
        onClick={() => handleChangeTab(1)}
      >
        Detail Transaksi
      </div>
      <FilterAnalytic tab={tab} />
      {tab === 0 ? (
        <SectionAnalytic />
      ) : (
        <DetailTransactionOwner
          highestSell={highestSell}
          detTransRevDetail={detTransRevDetail}
          detTransRev={detTransRev}
          detHighestRev={detHighestRev}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px 72px;
  div.menu {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #5c6366;
    display: inline-block;
    margin-right: 24px;
    cursor: pointer;
  }
  div.menu.active {
    color: #009eff;
  }
`;

export default AnalyticOwner;
