import React from "react";
import styled from "styled-components";
import LoginImage from "../assets/images/login-image.png";
import { LoginForm } from "../components";

const Login = () => {
  return (
    <Wrapper>
      <LoginForm />
      <div className="image">
        <img src={LoginImage} alt="" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: linear-gradient(167.96deg, #1c1d1f 0%, #1b2125 100%);
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div.image {
    margin-top: 192px;
    margin-left: 11.11%;
  }
  @media screen and (max-width: 1200px) {
    div.image {
      display: none;
    }
  }
`;

export default Login;
